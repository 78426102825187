import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../components/HomeView.vue'
import UploadView from '../components/UploadView.vue'
import Upscalling from '../components/AIFeatures/Upscalling.vue'
import FaceRetouching from '../components/AIFeatures/FaceRetouching.vue'
import Denoising from '../components/AIFeatures/Denoising.vue'
import BGRemoving from '../components/AIFeatures/BGRemoving.vue'
import Colorizing from '../components/AIFeatures/Colorizing.vue'
import ColorCalibrating from '../components/AIFeatures/ColorCalibrating.vue'
import HistoryListView from '../components/common/HistoryListView.vue'
import CreditsView from '../components/Credits/CreditsView.vue'
import PriceView from '../components/Credits/PriceView.vue'
import Remover from '../components/common/AIRemover.vue'


Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}


const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/Upload',
    name: 'Upload',
    component: UploadView,
  },
  {
    path: '/upscale-image',
    name: 'Upscalling',
    component: Upscalling
  },
  {
    path: '/face-refinement',
    name: 'FaceRetouching',
    component: FaceRetouching
  },
  {
    path: '/image-denoise',
    name: 'Denoising',
    component: Denoising
  },
  {
    path: '/remove-background',
    name: 'BGRemoving',
    component: BGRemoving
  },
  {
    path: '/colorize-image',
    name: 'Colorizing',
    component: Colorizing
  },
  {
    path: '/color-calibrate',
    name: 'ColorCalibrating',
    component: ColorCalibrating
  },
  {
    path: '/HistoryListView',
    name: 'HistoryListView',
    component: HistoryListView
  },
  {
    path: '/upgrade',
    name: 'CreditsView',
    component: CreditsView
  },
  {
    path: '/get-free-credits',
    name: 'PriceView',
    component: PriceView
  },
  {
    path: '/remove-objects',
    name: 'Remover',
    component: Remover
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
