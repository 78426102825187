<template>
  <div class="histroy_photo_list fullContent" v-loading="handleLoadingShow" >
    <div class="r-message history" v-if="historyMessageShow && currentPhotoList.length !== 0">
      {{ $t('The completed photos will be deleted after {expirationDate} hours, please download them to your computer in time.', { expirationDate: this.config.EXPIRATION_DATA}) }}
      <img class="close" :src="$Image('close.png')" alt="" @click="onClose">
    </div>



    <div class="fullContent group">
      <div class="history_phonew flexColCenter">

        <div class="photo-item flexRowBetween history history_phone" v-for="item in enhanceProcessStatus" :key="item.rid">
          <div class="left flexRowStart padding_lr20">
            <img :src="item.image" class="thumbnail"  @error="errorImg"/>
            <div class="info-container flex-col-center-lefts">
              <div class="name">{{ item.name }}</div>

              <div class="flexRowBetween model-container">
                <div class="model"> {{ item.label }}</div>
              </div>

              <div class="status flexRowStart" v-if="item.status === 'progress'">
                <el-progress :stroke-width="11" :percentage="item.progress" :color="customColor" style="width:286px;"></el-progress>
              </div>

              <div class="status flexRowStart" style="color: #4a4b65;" v-else-if="['active', 'waiting'].includes(item.status)">
                <img :src="$Image('ico_queuing.png')" class="status-img" alt="queuing" />
                  {{ $t('waiting...') }}
              </div>

            </div>
          </div>
        </div>

      </div>


      <div class="history_phonew flexColCenter">
          <div class="photo-item flexRowBetween history history_phone position_delete" v-for="item in currentPhotoList" :key="item.rid" @dblclick="onPreview(item)">
            <div class="left flexRowStart padding_lr20">
              <img :src="item.image" class="thumbnail" />
              <div class="info-container flex-col-start-lefts">
                <div class="name">{{ item.name }}</div>
                <div class="model"> {{ item.label }}</div>
                <div class="flexRowBetween model-container">

                  <span v-if="item.status === 'failed'">{{ $t('Failed:') }} {{ $t(item.error_msg) }}</span>

                  <div class="status flexRowStart" v-else-if="item.status === 'complted'">
                    <img :src="$Image('9_ready.png')" class="status-img" alt="ready" />
                    {{ $t('Ready') }}
                  </div>

                  <button :disabled="item.status === 'failed'" class="preview" @click.stop="onPreview(item)">
                    <img :src="$Image('preview.png')" class="thumbnail" />
                  </button>


                </div>

                <div class="upload_drag flexRowCenter">
                  <div class="config_upload flexRowStart">
                    <div :disabled="item.status === 'failed'" class="click_upload flexRowCenter" @click.stop="onDownload(item)">
                      <img :src="$Image('14_download_in_hign_quality.png')" alt="">
                      <span>
                        {{ $t('Download in hign quality') }}
                      </span>
                    </div>
                  </div>
                </div>

              </div>



            </div>

            <el-button class="delete" @click.stop="onDelete(item, true)">
              <img :src="$Image('del-p.png')" class="thumbnail" />
            </el-button>

          </div>
          <div class="photo-item flexRowBetween history history_phone position_delete" v-for="item in errorItem" :key="item.id + item.date">
            <div class="left flexRowStart padding_lr20">
              <img :src="item.image" class="thumbnail" />
              <div class="info-container flex-col-start-lefts">
                <div class="name">{{ item.name }}</div>
                <div class="model"> {{ item.label }}</div>
                <div class="flexRowBetween model-container">
                  <div class="status flexRowStart" style="color: #d80650">
                    <img :src="$Image('15_failed.png')" class="status-img" alt="error" />
                    {{ $t('Error') }}
                  </div>
                </div>
              </div>



            </div>

            <el-button class="delete" @click.stop="onDeleteError(item)">
              <img :src="$Image('del-p.png')" class="thumbnail" />
            </el-button>

          </div>
      </div>

    <div v-if="currentPhotoList.length === 0" class="fullContent flexColCenter">
      <img :src="$Image('17_picture_preview_failed.png')" alt="">
      <div class="empty-message">
        {{ $t("You don't have any processed Images") }}
      </div>
    </div>


      <FooterView></FooterView>
    </div>



    <PreviewModalView></PreviewModalView>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import FooterView from './Footer.vue'
import PreviewModalView from './PreviewModal.vue'
import * as Mutations from '@/store/mutations.js'
import bus from '@/assets/js/bus'
// import { tagStyleArray } from '@/assets/js/tagColor'
import { downloadFile, batchDownloadFile } from "@/assets/js/utils/download.js";
import { getProcessHistory, deleteHistoryImages, enhanceImage, getProcessStatus } from '@/assets/js/request/api'

export default {
  name: 'PhotoList',
  data() {
    return {
      defaultDeleteMsg: this.$t('Delete this image?'),
      historyMessageShow: true,
      checkedHistoryList: [],
      checkAll: false,
      currentPhotoList: [],
      handleLoadingShow: false,
      enhanceProcessStatus: [],
      processStatus: [],
      errorItem: [],
      customColor: '#fe5758',
      onImagesShow: false
    }
  },
  methods: {
    onDeleteError({id, date}){
      this.errorItem = this.errorItem.filter(i=> i.id!==id && i.date !==date)
      localStorage.setItem('errorItem', JSON.stringify(this.errorItem))
    },
    onDelete(images) {
      console.log('222222222', images)
      const ids = [images.rid]

      this.$confirm(this.$t('Delete this image?'), {
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: this.$t('confirm'),
        iconClass: this.$t('confirm-icon')
      }).then(async () => {
        const queryString = {
          sid: this.userId
        }
        // const params = isAll ? { deleteAll: true } : { deleteAll: false, records: ids}
        // const params = { deleteAll: true }
        const params = { deleteAll: false, records: ids}
        console.log('deleteHistoryImagesParams: ', queryString, params)
        const deleteResult = await deleteHistoryImages({ queryString, params })
        console.log('deleteResult: ', deleteResult)
        if (deleteResult.data.SubscriptCredit) {
          const { ImageCredit, UploadCredit, VideoCredit, SubscriptCredit } = deleteResult.data
          const credits = {
            VideoCredit,
            ImageCredit,
            UploadCredit,
            SubscriptCredit
          }
          sessionStorage.setItem('Credits', JSON.stringify(credits))
          this.$store.commit(Mutations.setCredits, credits)
        }

        if (deleteResult.code === 0) {
          this.$message({
            type: 'success',
            message: this.$t('Deleted successfully')
          })

          this.currentPhotoList = this.currentPhotoList.filter(item => ids.indexOf(item.rid) === -1)
          // console.log(this.currentPhotoList.length)
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('Delete cancel')
        })
      })

    },
    // onBatchDelete() {
    //   console.log('history onBatchDelete')
    //   const msg = this.$t('Delete All images?')
    //   const isAll = this.checkedHistoryList.length === this.currentPhotoList.length
    //   this.$emit('deleteImages', this.checkedHistoryList, msg, true, isAll)
    // },
    // 多个下载
    onBatchDownload() {
      batchDownloadFile(this.downloadArray)
    },
    //预览
    onPreview(item) {
      console.log('onPreview', item, this.$modal)
      if (item.status === 'failed') return

      this.$modal.show('PreviewModal', {
        currentImage: item
      })
    },
    onDownload(item) {
      console.log('onDownload', item, item.dstImage)
      downloadFile(item.dstImage, item.name)
    },
    onClose() {
      this.historyMessageShow = false
    },
    async getCurrentPhotoList() {
        const queryString = {
          sid: this.userId
        }
        const params = []
        const imagesResult = await getProcessHistory({ queryString, params })
        console.log('GetProcessHistory Success, processHistoryList: ', imagesResult)
        const images = imagesResult?.data?.images
        images.forEach(image => {
          image.image = this.config?.BASE_URL + image.image
          image.thumb = this.config?.BASE_URL + image.thumb
          image.dstImage = this.config?.BASE_URL + image.dstImage
          image.dstThumb = this.config?.BASE_URL + image.dstThumb
        })

        return images
    },
    async onEnhance (data) {
      try {
          console.log('onEnhance')
          const enhanceResult  = await enhanceImage(data)
          const enhanceResultData = enhanceResult.data
          console.log('ProcessImage Success, result: ', JSON.stringify(enhanceResultData))
          if (!enhanceResultData) return
          if (!this.enhanceProcessStatus) return
          if (this.isEnhancing) return
          bus.$emit('onProcessed', this.onHistory)

          const { queryInterval, processStatus } = enhanceResultData
          console.log('queryInterval, processStatus', queryInterval, processStatus)
          this.enhanceProcessStatus = JSON.parse(localStorage.getItem('queryParam'))
          this.processStatus = processStatus

          if (queryInterval === 0 || processStatus.length === 0) {
            this.enhanceProcessStatus = []
            // console.log('processStatus.length', processStatus.length)
            this.$store.commit(Mutations.endProgress)
          } else {
            this.$store.commit(Mutations.startProgress)
            this.handleProcessStatus(processStatus)
            setTimeout(() => {
              this.getProcess()
            }, queryInterval)
          }
        } catch (error) {
          console.log('onEnhance error:', error)
        }
    },
    async getProcess() {
      this.enhanceProcessStatus = JSON.parse(localStorage.getItem('queryParam'))
      const { data } = await getProcessStatus({sid: this.userId})
      console.log('GetProcessStatus In getProcess Success, processStatus: ', data)
      const { queryInterval, processStatus, credits } = data

      credits && sessionStorage.setItem('Credits', JSON.stringify(credits))
      credits && this.$store.commit(Mutations.setCredits, credits)
      //如果执行中的任务数量减少 检查是哪个任务完成了
      if (processStatus.length < this.processStatus.length) {
        const completeIdArray = this.processStatus.filter(item => !processStatus.some(ele => ele.id === item.id)).map(item => item.id)
        this.enhanceProcessStatus.forEach(item => {
          if (completeIdArray.includes(item.id)) {
            console.log('完成ID', item.id)
            const queryString = {
              sid: this.userId
            }
            const params = {
              images: [item.id]
            }
            //检查历史记录中是否有该任务
            getProcessHistory({ queryString, params }).then(async (res) => {
              const images = res?.data?.images
              images.forEach(image => {
                image.image = this.config?.BASE_URL + image.image
                image.thumb = this.config?.BASE_URL + image.thumb
                image.dstImage = this.config?.BASE_URL + image.dstImage
                image.dstThumb = this.config?.BASE_URL + image.dstThumb
              })
              console.log('images', images, JSON.stringify(item))
              this.enhanceProcessStatus = this.enhanceProcessStatus.filter(f => f.id.indexOf(item.id) === -1)
              localStorage.setItem('queryParam',JSON.stringify(this.enhanceProcessStatus))

              if (!images.length) {
                  this.$message.error(`${item.name} ${item.label}, please try again or contact our customer service.`)
                  let errorItem = localStorage.getItem('errorItem') || '[]'
                  errorItem = [{...item, date: Number(new Date())}, ...JSON.parse(errorItem)]
                  localStorage.setItem('errorItem', JSON.stringify(errorItem))
                  this.errorItem = errorItem
              }

              this.currentPhotoList.unshift(...images)
            })
          }
        })
      }
      this.processStatus = processStatus

      // if (processStatus.length === 0) {
      //   localStorage.removeItem('queryParam')
      //   this.enhanceProcessStatus = []
      // }
      if (queryInterval === 0 || processStatus.length === 0) {
        this.$store.commit(Mutations.endProgress)
        // localStorage.removeItem('queryParam')
      } else {
        this.$store.commit(Mutations.startProgress)
        this.handleProcessStatus(processStatus)
        setTimeout(() => {
          this.getProcess()
        }, queryInterval)
      }
    },
    // 修改photoList中的进度状态
    handleProcessStatus(processList) {
      processList.forEach(process => {
        const photoObj = this.enhanceProcessStatus.find(photo => process.id === photo.id)
        if (!photoObj) return
        photoObj.status = process.status
        this.$set(photoObj, 'progress', process.progress)
        localStorage.setItem('queryParam',JSON.stringify(this.enhanceProcessStatus))
      })
    },
    errorImg (event) {
      let img = event.srcElement
      img.src = require('@/assets/images/image_loading.png')
      img.onerror = null
    }
  },
  components: {
    PreviewModalView,
    FooterView
  },
  mounted() {
    this.enhanceProcessStatus = JSON.parse(localStorage.getItem('queryParam'))
    let errorItem = localStorage.getItem('errorItem') ? JSON.parse(localStorage.getItem('errorItem')) : []
    //过滤超过72小时的
    errorItem = errorItem.filter(item=>{
        let currentDate = Number(new Date())
        let ExpirationTime = 1000 * 60 * 60 * 24 * 3
        return (currentDate - item.date) < ExpirationTime
    })
    localStorage.setItem('errorItem', JSON.stringify(errorItem))
    this.errorItem = errorItem
    console.log('enhanceProcessStatus:', this.enhanceProcessStatus)
    console.log('enhanceData:', this.enhanceData)
    if (this.enhanceProcessStatus && !this.enhanceData) {
      console.log('getProcess:')
      this.getProcess()
    }
    if (this.enhanceData) {
      this.onEnhance(this.enhanceData)
    }
    // this.getProcess()
    this.getCurrentPhotoList().then(async (res) => {
      this.currentPhotoList = res
    })
    bus.$on('onEnhance', this.onEnhance)
  },
  computed: {
    ...mapState(['userId', 'config', 'isEnhancing', 'enhanceData']),
    downloadArray() {
      return this.checkedHistoryList.map(item => {
        return {url: item.dstImage, name: item.name}
      })
    }
  },
  // 监听路由
  watch: {
    '$route' (to) {
      if (to.name === 'HistoryListView') {
        this.getProcess()
        this.getCurrentPhotoList().then(async (res) => {
          this.currentPhotoList = res
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/module/history-list.scss';
</style>
