/**
 * 下载非同源文件
 * @param {String} fileUrl
 * @param {String} fileName
 */
export const downloadFile = (fileUrl, fileName) => {
    if (!fileUrl) return
    const a = document.createElement('a')

    a.href = fileUrl
    a.download = decodeURI(fileName)
    a.style.display = 'none'
    document.body.appendChild(a)
    a.click()
    a.parentNode.removeChild(a)
}

/**
 * 批量下载文件
 * @param {Array} downloadArray
 */
export const batchDownloadFile = async downloadArray => {
    if (!downloadArray || downloadArray.length === 0) return
    
    downloadArray.forEach(downloadObj => {
        const iframe = document.createElement('iframe')
        iframe.style.display = 'none'
        iframe.style.height = 0
        iframe.src = downloadObj.url
        document.body.appendChild(iframe)
        setTimeout(() => {
            iframe.remove()
        }, 5 * 60 * 1000)
    })
}