<template>
<div class="fullContent" style="position: relative;height:100%;">
  <div class="upscalling_main">

    <div class="upload-view upload_bgc flexColCenter">

      <div class="flexRowCenter mobile_col" style="padding: 58px 0 30px">
        <div class="flexColStart photo_title">
          <div class="photo_title_g">
            {{ $t('AI Image Denoiser: Remove Image Noise Online Free') }}
            
          </div>
          <div class="photo_title_t">
            {{ $t('Use the AI-based Image Denoiser to automatically and correctly reduce or remove noise from a noisy image, so as to restore the true image and recover significant information from noisy images, without the noise reduction filters.') }}
           
          </div>
        </div>
        <img :src="$Image('denoising_top_banner.png')" alt="">
      </div>
      
      <div class="flexColCenter photo_upload " ref='select_frame'  ondragstart="return false">
        <p class="photo_upload_d">
          {{ $t('Drag image here or click to upload.') }}
          </p>
        <div class="upload_drag flexRowCenter">
          <div class="config_upload flexRowStart">
            <div class="click_upload flexRowCenter" @click="onChoose">
              <img :src="$Image('upload_image.png')" alt="">
              <span>
                {{ $t('Upload Image') }}
              </span>
            </div>
          </div>
        </div>
        <p class="photo_upload_l" @click="onDownloadMore">
          {{ $t('Download App to unlock more features') }}   ></p>
      </div>

    </div>
  
    <!-- img5 -->
    <div class="flexColCenter upload-view form_image">
      <div class="form_image_u">
        {{ $t('AI Image Denoiser: Noise Reduction') }}
       
      </div>
       <p class="form_image_a mar_t">
        {{ $t("As the images captured by modern cameras are inevitably degraded by noise, which leads to deteriorated visual image quality, a useful tool is required to reduce noise without losing image features like edges, corners, and other sharp structures.") }}
   
      </p>
      <p class="form_image_a mar_tb">
              {{ $t('This image denoiser based on fast and flexible denoising convolutional neural network performs well in image denoising and real details recovery.') }}
      </p>

      <CustomizeImg :imgUrl='sharpen1' :enhancerUrl='sharpen2' :handlelefts="handleleft" :parentClientWidths="parentClientWidth"></CustomizeImg>

      <div class="flexRowCenter mar_t">
        <div class="config_upload flexRowStart" @click="onTryFree">
          <div class="click_upload click_btn_w flexRowCenter">
            <span>
              {{ $t('Try it for free') }}
            </span>
          </div>
        </div>
        <div class="google_play_btn flexRowStart">
          <div class="click_google_play download_w flexRowCenter" @click="onDownload">
            <div class="download_icon"></div>
            <span>
              {{ $t('Download for Win&Mac') }}
            </span>
          </div>
        </div>
      </div>


    </div>

    <!-- img -->
    <div class="flexColCenter upload-view form_image last_mb">
      <div class="form_image_u">
        {{ $t('Try AI Noise Reduction Online To Make Your Various Photographys More Clear') }}
       
      </div>
       <p class="form_image_a mar_tb">
        {{ $t('Whether you are a photographer, web designer, eCommerce, blogger, our AI Image Denoiser is your best choice ever to remove noise/grain from portrait, wildlife, night, fashion, landscape photographs.') }}
      </p>
 
      <img :src="$Image('denoising_noise.png')" alt="">
      

      <div class="flexRowCenter mar_t">
        <div class="config_upload flexRowStart" @click="onTryFree">
          <div class="click_upload click_btn_w flexRowCenter">
            <span>
              {{ $t('Try it for free') }}
            </span>
          </div>
        </div>
        <div class="google_play_btn flexRowStart">
          <div class="click_google_play download_w flexRowCenter" @click="onDownload">
            <div class="download_icon"></div>
            <span>
              {{ $t('Download for Win&Mac') }}
            </span>
          </div>
        </div>
      </div>


    </div>
  
    <FooterView></FooterView>
  </div>
</div>

</template>


<!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NK52HVJ');</script>
<!-- End Google Tag Manager -->

<script>
import CustomizeImg from '../common/CustomizeImg.vue'
import FooterView from '../common/Footer.vue'
import bus from '@/assets/js/bus'
import { getCookie } from '@/assets/js/utils/cookie'
export default {
  name: 'Denoising',
  metaInfo: {
    title: 'AI Image Denoiser and Noise Reduction Tool to Remove Noise and Grain from Images',
    meta: [
      {
        name: 'description',
        content: 'Powered by AI noise reduction technology, this AI online image denoiser helps you to get rid of noises and grain from photos automatically and recover details to enhance image quality.'
      },
      {
        name: 'keywords',
        content: 'photo noise reduction, ai noise reduction, denoise ai, remove noise from images'
      }
    ]
  },
  data() {
    return {
      handleleft: 926/2,
      parentClientWidth: 926,
      sharpen1: 'denoising1.jpg',
      sharpen2: 'denoising2.jpg'
    }
  },
  components: {
    CustomizeImg,
    FooterView
  },
  methods: {
    onDownloadMore () {
      const lang = getCookie('lang')
      switch (lang) {
        case 'en':
          window.open('https://www.avclabs.com/download.html')
          break
        case 'de':
          window.open('https://www.avclabs.de/download.html')
          break
        case 'fr':
          window.open('https://www.avclabs.com/download.html')
          break
        case 'es':
          window.open('https://www.avclabs.com/download.html')
          break
        case 'ja':
          window.open('https://www.avclabs.jp/download.html')
          break
        default:
          window.open('https://www.avclabs.com/download.html')
          break
      }
    },
    onTryFree () {
      this.$router.push({path:'/'})
    },
    onChoose () {
      bus.$emit('uploadImage')
      bus.$emit('emitSelect', 'Denoising')
      this.$router.push({path:'/'})
    },
    onDownload () {
      window.location.href = 'https://www.avclabs.com/download.html'
    }
  },

  mounted () {
    this.$refs.select_frame.ondragleave = (e) => {
        e.preventDefault()
    }
    this.$refs.select_frame.ondrop = (e) => {
        e.preventDefault()
        const data = e.dataTransfer.files
        if (data.length < 1) {
            return
        }
        // console.log('dataaaaaaa', data)
        bus.$emit('onGetFile', data)
        this.$router.push({path:'/'})
    }
    this.$refs.select_frame.ondragenter = (e) => {
        e.preventDefault()
    }
    this.$refs.select_frame.ondragover = (e) => {
        e.preventDefault()
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/scss/module/upload-view.scss';
</style>
