
import * as Mutations from './mutations.js'

const state = {
  isEnhancing: false,
  modelList: [],
  currentModelInfo: {
    label: 'Photo Sharpener',
    ai_list: [
      {
        name: 'FAST',
        params: {
          scale: 4,
          faceRefinement: true
        }
      }
    ]
  },
  currentDemo: [],
  userId: '',
  credits: {},
  showBuyTips: false,
  config: null,
  cosToken: null,
  loginShow: true,
  userName: '',
  AnonymousLogin: false,
  WorkspaceState: false,
  Loginstate: false,
  routerViewCut: true,
  setCustomizeState: true,
  enhanceData: null
}

const mutations = {
  [Mutations.setEnhanceData] (state, s) {
    state.enhanceData = s
  },
  [Mutations.setCustomize] (state, s) {
    // console.log('setCustomize', s)
    state.setCustomizeState = s
  },
  [Mutations.setViewtate] (state, v) {
    state.routerViewCut = v
  },
  [Mutations.setLoginstate] (state, t) {
    state.Loginstate = t
  },
  [Mutations.setWorkspaceState] (state, t) {
    state.WorkspaceState = t
  },
  [Mutations.setUserAnonymous] (state, t) {
    state.AnonymousLogin = t
  },
  [Mutations.setUserName] (state, name) {
    state.userName = name
  },
  [Mutations.setLogin] (state, type) {
    state.loginShow = type
  },
  [Mutations.startProgress] (state) {
    console.log('Start_Progress')
    state.isEnhancing = true
  },
  [Mutations.endProgress] (state) {
    console.log('End_Progress')
    state.isEnhancing = false
  },
  [Mutations.setModelList] (state, modelList) {
    state.modelList = modelList
  },
  [Mutations.setCurrentModelInfo] (state, currentModelInfo) {
    console.log('setCurrentModelInfo', currentModelInfo)
    state.currentModelInfo = currentModelInfo
  },
  [Mutations.setUserId] (state, Sid) {
    state.userId = Sid
  },
  [Mutations.setCredits] (state, credits) {
    state.credits = credits
  },
  [Mutations.setShowBuyTips] (state, showBuyTips) {
    state.showBuyTips = showBuyTips
  },
  [Mutations.setCurrentDemo] (state, currentDemo) {
    state.currentDemo = currentDemo
  },
  [Mutations.setConfig] (state, config) {
    state.config = config
  },
  [Mutations.setCosToken] (state, cos) {
    state.cosToken = cos
  }
}

const actions = {
  // async updateCurrentModel ({ state, commit }, { modelName }) {
  //   const model = state.modelList.find(v => v.label === modelName)
  //   console.log('updateCurrentModel:', model)
  //   if (model) {

  //     const { ai_list } = model
      
  //     let info = {
  //       label: model.label,
  //       ai_list: []
  //     }
      
  //     ai_list.forEach(item => {
  //       const { name, params } = item
  //       let infoParams = {}
        
  //       params.forEach(item => {
  //         const { name } = item
  //         infoParams[name] = item.default
  //       })
        
  //       info.ai_list.push({
  //         name,
  //         params: infoParams
  //       })
  //     })
  //     console.log('updateCurrentModel_info:', info)
  //     commit(Mutations.setCurrentModelInfo, info)
  //   }
  // }
}


export default {
  state,
  mutations,
  actions
}
