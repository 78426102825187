<template>
<div class="fullContent" style="position: relative;height:100%;">
  <div class="upscalling_main">

    <div class="upload-view upload_bgc flexColCenter">

      <div class="flexRowCenter mobile_col" style="padding: 58px 0 30px">
        <div class="flexColStart photo_title">
          <div class="photo_title_g" style="text-align: left;width:100%">
            {{ $t('AI Photo Colorizer') }}
           
          </div>
          <div class="photo_title_t">
            {{ $t('AI photo colorizer that helps automatically colorize old black and white photos.') }}
          </div>
        </div>
        <img :src="$Image('color_calibrating_top_banner.png')" alt="">
      </div>
      
      <div class="flexColCenter photo_upload " ref='select_frame'  ondragstart="return false">
        <p class="photo_upload_d">
          {{ $t('Drag image here or click to upload.') }}
          </p>
        <div class="upload_drag flexRowCenter">
          <div class="config_upload flexRowStart">
            <div class="click_upload flexRowCenter" @click="onChoose">
              <img :src="$Image('upload_image.png')" alt="">
              <span>
                {{ $t('Upload Image') }}
              </span>
            </div>
          </div>
        </div>
        <p class="photo_upload_l" @click="onDownloadMore">
          {{ $t('Download App to unlock more features') }}  ></p>
      </div>

    </div>
  
    <!-- img5 -->
    <div class="flexColCenter upload-view form_image last_mb">
      <div class="form_image_u">
        {{ $t('AI Calibrate Photo Color') }}
        
      </div>
      <p class="form_image_y">
        {{ $t(" It is hard to control lighting conditions, and as a result, the colors in your photos can end up looking washed out or inaccurate. This photo AI tool includes a color calibration feature that can help you achieve natural color rendition, high detail, and high quality in your photos. The cutting-edge color calibration technology ensures that your photos always look natural and have accurate colors. Whether you're shooting in bright sunlight or in dim indoor lighting, you can be confident that your photos will come out looking great.") }}

      </p>

      <CustomizeImg :imgUrl='sharpen1' :enhancerUrl='sharpen2' :handlelefts="handleleft" :parentClientWidths="parentClientWidth"></CustomizeImg>

        <div class="flexRowCenter mar_t">
        <div class="config_upload flexRowStart" @click="onTryFree">
          <div class="click_upload click_btn_w flexRowCenter">
            <span>
              {{ $t('Try it for free') }}
            </span>
          </div>
        </div>
        <div class="google_play_btn flexRowStart">
          <div class="click_google_play download_w flexRowCenter" @click="onDownload">
            <div class="download_icon"></div>
            <span>
              {{ $t('Download for Win&Mac') }}
            </span>
          </div>
        </div>
      </div>


    </div>
  
    <FooterView></FooterView>
  </div>
</div>

</template>



<!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NK52HVJ');</script>
<!-- End Google Tag Manager -->

<script>
import CustomizeImg from '../common/CustomizeImg.vue'
import FooterView from '../common/Footer.vue'
import bus from '@/assets/js/bus'
import { getCookie } from '@/assets/js/utils/cookie'
export default {
  name: 'ColorCalibrating',
  metaInfo: {
    title: 'AI Color Calibrator to Give Photos a More Natural Look and Professional Tone',
    meta: [
      {
        name: 'description',
        content: 'The AI Color Calibrator uses neural networks to correct the color cast of the photos, and restore balanced tones and vibrant colors.'
      },
      {
        name: 'keywords',
        content: 'color calibrator, color calibration tool online, ai color calibration, correct image color, and tone'
      }
    ]
  },
  data() {
    return {
      handleleft: 926/2,
      parentClientWidth: 926,
      sharpen1: 'color_calibrating1.jpg',
      sharpen2: 'color_calibrating2.jpg'
    }
  },
  components: {
    CustomizeImg,
    FooterView
  },
  methods: {
    onDownloadMore () {
      const lang = getCookie('lang')
      switch (lang) {
        case 'en':
          window.open('https://www.avclabs.com/download.html')
          break
        case 'de':
          window.open('https://www.avclabs.de/download.html')
          break
        case 'fr':
          window.open('https://www.avclabs.com/download.html')
          break
        case 'es':
          window.open('https://www.avclabs.com/download.html')
          break
        case 'ja':
          window.open('https://www.avclabs.jp/download.html')
          break
        default:
          window.open('https://www.avclabs.com/download.html')
          break
      }
    },
    onTryFree () {
      this.$router.push({path:'/'})
    },
    onChoose () {
      bus.$emit('uploadImage')
      bus.$emit('emitSelect', 'ColorCalibrating')
      this.$router.push({path:'/'})
    },
    onDownload () {
      window.location.href = 'https://www.avclabs.com/download.html'
    }
  },

  mounted () {
    this.$refs.select_frame.ondragleave = (e) => {
        e.preventDefault()
    }
    this.$refs.select_frame.ondrop = (e) => {
        e.preventDefault()
        const data = e.dataTransfer.files
        if (data.length < 1) {
            return
        }
        // console.log('dataaaaaaa', data)
        bus.$emit('onGetFile', data)
        this.$router.push({path:'/'})
    }
    this.$refs.select_frame.ondragenter = (e) => {
        e.preventDefault()
    }
    this.$refs.select_frame.ondragover = (e) => {
        e.preventDefault()
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/scss/module/upload-view.scss';
</style>
