import { setCookie } from './utils/cookie'

/**
 * 获取上一个页面的url, 以此来设置cookie
 */
export const setLangCookie = () => {
    const prevUrl = document.referrer

    switch (prevUrl) {
      case 'https://www.avclabs.com/':
        setCookie('lang', 'en')
        break;
      case 'https://www.avclabs.de/':
        setCookie('lang', 'de')
        break;
      case 'https://www.avclabs.jp/':
        setCookie('lang', 'ja')
        break;
      case 'https://www.avclabs.com/fr/':
        setCookie('lang', 'fr')
        break;
      case 'https://www.avclabs.com/es/':
        setCookie('lang', 'es')
        break;
      default:
        break;
    }
}