<template>
<div class="fullContent" style="position: relative;height:100%;">
  <div class="credits_main">
    <div class="page-buy-title-area bg-11">
			<div class="container">
				<div class="page-buy-title-content mt-50">
					<h2>{{$t('Affordable Price & Plans for You!')}}</h2>
				</div>
			</div>
		</div>

    <div class="flexColCenter">
      <el-tabs v-model="activeName">
        <el-tab-pane :label="$t('Month')"  name="first">
          <div class="monthly_main">
            <div class="section-title">
              <p> {{$t('*Credits enable you to use all AI-powered features. The consumed credits differ depending on the different features you select.')}} </p>
            </div>

            <div class="flexRowCenter mobile_col_card">

              <div class="single-plans-card">
                <div class="pricing-header">
                  <h3>100</h3>
                  <p>{{$t('Credits')}}</p>
                </div>
                <div class="price">
                    <h4>{{$t('$9.95')}}<span>/{{$t('month')}}</span></h4>
                </div>
                <div class="features-list">
                  <h5>{{$t('ALL BENEFITS')}}:</h5>
                  <ul>
                    <li><i class="bx bx-check-circle el-icon-circle-check"></i>
                    {{$t('Roll over up to 5-month of single monthly credit')}}
                     </li>
                    <li><i class="bx bx-check-circle el-icon-circle-check"></i>
                    {{$t('Auto-renew. Cancel anytime')}}
                     </li>
                    <li><i class="bx bx-check-circle el-icon-circle-check"></i>
                    {{$t('24/7 email support')}}
                     </li>
                    <li><i class="bx bx-check-circle el-icon-circle-check"></i> 
                    {{$t('Privacy Protection')}}
                    </li>
                  </ul>
                </div>
                <a href="#!" id="buy_photo_100_1month" @click="addcheck100" class="link-btn normal_btn">{{$t('Subscribe')}}</a>
                <div class="plans-shape">
                  <img :src="$Image('plan-shape.png')" alt="">
                </div>
              </div>

              <div class="single-plans-card">
                <div class="pricing-header">
                  <h3>300</h3>
                  <p>{{$t('Credits')}}</p>
                </div>
                <div class="price">
                    <h4>{{$t('$25.95')}}<span>/{{$t('month')}}</span></h4>
                </div>
                <div class="features-list">
                  <h5>{{$t('ALL BENEFITS')}}:</h5>
                  <ul>
                    <li><i class="bx bx-check-circle el-icon-circle-check"></i>
                    {{$t('Roll over up to 5-month of single monthly credit')}}
                     </li>
                    <li><i class="bx bx-check-circle el-icon-circle-check"></i>
                    {{$t('Auto-renew. Cancel anytime')}}
                     </li>
                    <li><i class="bx bx-check-circle el-icon-circle-check"></i>
                    {{$t('24/7 email support')}}
                     </li>
                    <li><i class="bx bx-check-circle el-icon-circle-check"></i> 
                    {{$t('Privacy Protection')}}
                    </li>
                  </ul>
                </div>
                <a href="#!" id="buy_photo_300_1month" @click="addcheck300" class="link-btn normal_btn">{{$t('Subscribe')}}</a>
                <div class="plans-shape">
                  <img :src="$Image('plan-shape.png')" alt="">
                </div>
              </div>

              <div class="single-plans-card">
                <div class="pricing-header">
                  <h3>500</h3>
                  <p>{{$t('Credits')}}</p>
                </div>
                <div class="price">
                  <h4>{{$t('$39.95')}}<span>/{{$t('month')}}</span></h4>
                </div>
                <div class="features-list">
                  <h5>{{$t('ALL BENEFITS')}}:</h5>
                  <ul>
                    <li><i class="bx bx-check-circle el-icon-circle-check"></i>
                    {{$t('Roll over up to 5-month of single monthly credit')}}
                     </li>
                    <li><i class="bx bx-check-circle el-icon-circle-check"></i>
                    {{$t('Auto-renew. Cancel anytime')}}
                     </li>
                    <li><i class="bx bx-check-circle el-icon-circle-check"></i>
                    {{$t('24/7 email support')}}
                     </li>
                    <li><i class="bx bx-check-circle el-icon-circle-check"></i> 
                    {{$t('Privacy Protection')}}
                    </li>
                  </ul>
                </div>
                <a href="#!" id="buy_photo_500_1month" @click="addcheck5001month" class="link-btn normal_btn">{{$t('Subscribe')}}</a>
                <div class="plans-shape">
                  <img :src="$Image('plan-shape.png')" alt="plan">
                </div>
              </div>

              <div class="single-plans-card">
                <div class="pricing-header pb-65">
                  <h3>{{$t('Custom')}}</h3>
                  <p>{{$t('Tell Us the Credits You Want')}}</p>
                </div>
                <div class="features-list">
                  <h5>{{$t('ALL BENEFITS')}}:</h5>
                  <ul>
                    <li><i class="bx bx-check-circle el-icon-circle-check"></i>
                    {{$t('Roll over up to 5-month of single monthly credit')}}
                     </li>
                    <li><i class="bx bx-check-circle el-icon-circle-check"></i>
                    {{$t('Auto-renew. Cancel anytime')}}
                     </li>
                    <li><i class="bx bx-check-circle el-icon-circle-check"></i>
                    {{$t('24/7 email support')}}
                     </li>
                    <li><i class="bx bx-check-circle el-icon-circle-check"></i> 
                    {{$t('Privacy Protection')}}
                    </li>
                  </ul>
                </div>
                <a href="mailto:support@avclabs.com" class="link-btn">{{$t('Contact Us')}}</a>
                <div class="plans-shape">
                  <img :src="$Image('plan-shape.png')" alt="plan">
                </div>
              </div>
            </div>  

          </div>
        </el-tab-pane>

        <el-tab-pane :label="$t('Pay as you go')" name="second">
          
          <div class="monthly_main">
            <div class="section-title">
              <p>
                {{$t('*Credits enable you to use all AI-powered features. The consumed credits differ depending on the different features you select.')}}
              </p>
            </div>

            <div class="flexRowCenter mobile_col_card">

              <div class="single-plans-card">
                <div class="pricing-header">
                  <h3>50</h3>
                  <p>{{$t('Credits')}}</p>
                </div>
                <div class="price">
                    <h4>{{$t('$19.95')}}<span>/{{$t('lifetime')}}</span></h4>
                </div>
                <div class="features-list">
                  <h5>{{$t('ALL BENEFITS')}}:</h5>
                  <ul>
                    <li><i class="bx bx-check-circle el-icon-circle-check"></i>
                    {{$t('Credits available for using anytime forever')}}
                    </li>
                    <li><i class="bx bx-check-circle el-icon-circle-check"></i> 
                    {{$t('24/7 email support')}}</li>
                    <li><i class="bx bx-check-circle el-icon-circle-check"></i>
                    {{$t('Privacy Protection')}} </li>
                  </ul>
                </div>
                <a href="#!" id="buy_photo_50_1month" @click="addCheckPay50" class="link-btn normal_btn">{{$t('Subscribe')}}</a>
                <div class="plans-shape">
                  <img :src="$Image('plan-shape.png')" alt="">
                </div>
              </div>

              <div class="single-plans-card">
                <div class="pricing-header">
                  <h3>200</h3>
                   <p>{{$t('Credits')}}</p>
                </div>
                <div class="price">
                    <h4>{{$t('$55.95')}}<span>/{{$t('lifetime')}}</span></h4>
                </div>
                <div class="features-list">
                  <h5>{{$t('ALL BENEFITS')}}:</h5>
                  <ul>
                    <li><i class="bx bx-check-circle el-icon-circle-check"></i>
                    {{$t('Credits available for using anytime forever')}}
                    </li>
                    <li><i class="bx bx-check-circle el-icon-circle-check"></i> 
                    {{$t('24/7 email support')}}</li>
                    <li><i class="bx bx-check-circle el-icon-circle-check"></i>
                    {{$t('Privacy Protection')}} </li>
                  </ul>
                </div>
                <a href="#!" id="buy_photo_200_1month" @click="addCheckPay200" class="link-btn normal_btn">{{$t('Subscribe')}}</a>
                <div class="plans-shape">
                  <img :src="$Image('plan-shape.png')" alt="">
                </div>
              </div>

              <div class="single-plans-card">
                <div class="pricing-header">
                  <h3>500</h3>
                  <p>{{$t('Credits')}}</p>
                </div>
                <div class="price">
                  <h4>{{$t('$97.95')}}<span>/{{$t('lifetime')}}</span></h4>
                </div>
                <div class="features-list">
                  <h5>{{$t('ALL BENEFITS')}}:</h5>
                  <ul>
                    <li><i class="bx bx-check-circle el-icon-circle-check"></i>
                    {{$t('Credits available for using anytime forever')}}
                    </li>
                    <li><i class="bx bx-check-circle el-icon-circle-check"></i> 
                    {{$t('24/7 email support')}}</li>
                    <li><i class="bx bx-check-circle el-icon-circle-check"></i>
                    {{$t('Privacy Protection')}} </li>
                  </ul>
                </div>
                <a href="#!" id="buy_photo_500_pay" @click="addCheckPay500" class="link-btn normal_btn">{{$t('Subscribe')}}</a>
                <div class="plans-shape">
                  <img :src="$Image('plan-shape.png')" alt="plan">
                </div>
              </div>

              <div class="single-plans-card">
                <div class="pricing-header pb-65">
                  <h3>{{$t('Custom')}}</h3>
                  <p>{{$t('Tell Us the Credits You Want')}}</p>
                </div>
                <div class="features-list">
                  <h5>{{$t('ALL BENEFITS')}}:</h5>
                  <ul>
                    <li><i class="bx bx-check-circle el-icon-circle-check"></i>
                    {{$t('Credits available for using anytime forever')}}
                    </li>
                    <li><i class="bx bx-check-circle el-icon-circle-check"></i> 
                    {{$t('24/7 email support')}}</li>
                    <li><i class="bx bx-check-circle el-icon-circle-check"></i>
                    {{$t('Privacy Protection')}} </li>
                  </ul>
                </div>
                <a href="mailto:support@avclabs.com" class="link-btn">{{$t('Contact Us')}}</a>
                <div class="plans-shape">
                  <img :src="$Image('plan-shape.png')" alt="plan">
                </div>
              </div>

            </div>  

          </div>

        </el-tab-pane>
      </el-tabs>
    </div>

    <div class="pb_100">

      <div class="products-details-tab">
        <ul class="tabs active">
          <li class="current">
            <a>
              <div>{{$t('Note')}}:</div>
            </a>
          </li>
        </ul>

        <div class="tab_content">
          <div class="tabs_item">
            <div class="products-details-tab-content">
              <p>
                {{$t("1. You should receive a confirmation email with your account information and top-up credits shortly after your purchase. If you do not receive the info, please check your spam filtering in case that the e-mail gets marked as spam and sorted into a 'Spam' folder or deleted.")}}
                </p>
              <p>
                {{$t('2. The payment via the payment gateway does not include value-added tax. You may need to pay VAT varies by country.')}}
                </p>
              <p>
                {{$t('3. Please log out of your account and log back in so as to the credits will arrive at your account.')}}
                </p>
              <p>
                {{$t('4. Ordering online is 100% secure! All data exchanged during the payment process is SSL-secured.')}}
                </p>
              <p>
                {{$t('5. If you have any questions regarding the subscription and account, please')}}
                 <a href="mailto:support@avclabs.com">{{$t('contact us')}}</a> {{$t('for more details.')}}</p>
            </div>
          </div>
        </div>

      </div>

    </div>

    <div class="pb_100">
      <div class="frequently_Questions">
        <div class="section-title">
					<h2>{{$t('Frequently Asked Questions')}}</h2>
				</div>

        <div class="faq-accordion">
          <ul class="accordion">

            <li class="accordion-item">
              <a class="accordion-title" @click="onCreditShow">
                <i class="bx bx-plus" :class="[creditShow ? 'el-icon-plus' : 'el-icon-minus']"></i>
                {{$t('1. What are the credits and how does the credit system work?')}}
                
              </a>
              <div class="accordion-content" v-show="!creditShow">
                <p>
                  {{$t('Credits are the virtual currency/game coins for using the online service. You can select a plan to purchase the credits you want. The single monthly credits can be rolled over up to 150 days. Your credits will be kept as long as the subscription is valid.')}}
                  </p>
                <p>
                  {{$t('Different amounts of credits will be required based on the AI features you apply when using the online service. Processing 1 image costs 1 credit. Upscaling 1 image up to 300% costs 3 credits, and 400% costs 4 credits. Using Face Refinement costs 2 credits.')}}
                  </p>
              </div>
            </li>

            <li class="accordion-item">
              <a class="accordion-title" @click="onPayShow">
                <i class="bx bx-plus" :class="[payShow ? 'el-icon-plus' : 'el-icon-minus']"></i>
                  {{$t('2. Can I use the online service if I don’t pay for it?')}}
              </a>
              <div class="accordion-content" v-show="!payShow">
                <p>
                  {{$t('Of course. We provide 8 free credits by default to allow users to evaluate AI image processing features. In addition, you will get 5 extra credits after registration, and output images without watermarks. If you want to get more credits, you can buy or')}}
                   <a @click="onEarn">
                  {{$t('earn free credits')}}
                  </a></p>
              </div>
            </li>

            <li class="accordion-item">
              <a class="accordion-title" @click="onKindShow">
                <i class="bx bx-plus" :class="[kindShow ? 'el-icon-plus' : 'el-icon-minus']"></i>
                {{$t('3. What kind of payment methods do you support?')}}
              </a>

              <div class="accordion-content" v-show="!kindShow">
                  <p>
                    {{$t('We accept payments via credit/debit cards and PayPal.')}}
                    </p>
              </div>
            </li>

            <li class="accordion-item">
              <a class="accordion-title" @click="onAutoShow">
                <i class="bx bx-plus" :class="[autoShow ? 'el-icon-plus' : 'el-icon-minus']"></i>
                {{$t('4. How to cancel the auto-renewal?')}}
                
              </a>
              <div class="accordion-content" v-show="!autoShow">
                    <p>
                      {{$t('The Auto-Renewal service is enabled by default, but it can be canceled')}}</p>
                    <p>
                      {{$t('There are two ways to cancel the auto-renewal')}}</p>
                    <p>(1) {{$t('After the order is completed, you will receive a confirmation email, where there is a link to cancel automatic renewal')}} </p>
                    <p>(2) {{$t('You can contact us by emailing support@avclabs.com, we will help you cancel the service quickly')}}</p>
              </div>
            </li>
            
            <li class="accordion-item">
              <a class="accordion-title" @click="onNeedShow">
                <i class="bx bx-plus" :class="[needShow ? 'el-icon-plus' : 'el-icon-minus']"></i>
                {{$t('5. What can I do if I need more credits? ')}}
                
              </a>

              <div class="accordion-content" v-show="!needShow">
                <p>
                  {{$t("If you run out of the credits in your account, you can either upgrade your subscription plan which offers more credits or select a 'pay-as-you-go' plan. Or you can")}} <a @click="onEarn">
                  {{$t('earn free credits')}}</a>.</p>
              </div>
            </li>
            
            <li class="accordion-item">
              <a class="accordion-title" @click="onPlanShow">
                <i class="bx bx-plus" :class="[planShow ? 'el-icon-plus' : 'el-icon-minus']"></i>
                {{$t("6. Which plans should I choose? 'Subscription' or 'pay as you go'?")}}
                
              </a>

              <div class="accordion-content" v-show="!planShow">
                <p>
                  {{$t("The plan 'pay-as-you-go' is a one-time purchase, so the credits are forever to use. It is highly recommended if you are a business user who has large numbers of images that need to be processed. The monthly subscription is more flexible as you can cancel the service at any time. It is so great that any of the unused credits will be rolled over to the following month (roll over up to 5-month of single monthly credit) as long as your subscription is active")}}</p>
              </div>
            </li>
            
            <li class="accordion-item">
              <a class="accordion-title" @click="onCanShow">
                <i class="bx bx-plus" :class="[canShow ? 'el-icon-plus' : 'el-icon-minus']"></i>
                {{$t('7. What can I do if I am not satisfied with the result?')}}
              </a>

              <div class="accordion-content" v-show="!canShow">
                <p>
                  {{$t('Our online AI service works excellently at upscaling and enhancing portraits, animes, landscape and wildlife photos, as well as photo denoising, colorizing, and color calibration. However, the results may vary with the complexity of images results. For a better user experience, we highly suggest you upload the test images or try sample images to see if our service can meet your requirements. If you are satisfied with the result, you can purchase the credits.')}}
                  </p>
                <p>
                  {{$t('In addition, we always improve and update the AI models to achieve the best and most natural result. If you have any suggestions, please feel free to tell us, so that we can improve.')}}
                  </p>
              </div>
            </li>
            
            <li class="accordion-item">
              <a class="accordion-title" @click="onSecureShow">
                <i class="bx bx-plus" :class="[secureShow ? 'el-icon-plus' : 'el-icon-minus']"></i>
                {{$t('8. Is it secure to use your online service?')}}
                 
              </a>

              <div class="accordion-content" v-show="!secureShow">
                <p>
                  {{$t("Customers' privacy is the first thing that we are concerned about. Do not worry about security and privacy. The uploaded images will be automatically cleared after 3 days. We promise we will not download and distribute your images or account information.")}}</p>
              </div>
            </li>
            
            <li class="accordion-item">
              <a class="accordion-title" @click="onPartyShow">
                <i class="bx bx-plus" :class="[partyShow ? 'el-icon-plus' : 'el-icon-minus']"></i>
                {{$t('9. Do you support API service for the third party?')}}
                
              </a>

              <div class="accordion-content" v-show="!partyShow">
                <p>
                  {{$t('If you need an API that is integrated into your website, you can contact us at support@avclabs.com.')}}
                  </p>
              </div>
            </li>
          </ul>
        </div>


      </div>
    </div>

  
    <FooterView></FooterView>
  </div>
</div>

</template>


    <script rel="stylesheet" type="text/javascript" src="https://payhut.me/pay/js/layui/css/layui.css"></script>
    <script rel="stylesheet" type="text/javascript" src="https://payhut.me/pay/css/payModal.css"></script>
    <script type="text/javascript" src="https://payhut.me/pay/js/layui/layui.all.js"></script>
    <script type="text/javascript" src="https://payhut.me/pay/js/messages.js"></script>
    <script type="text/javascript" src="https://js.stripe.com/v3/"></script>
    <script type="text/javascript" src="https://payhut.me/pay/js/pay.js"></script>


 

<script>
import FooterView from '../common/Footer.vue'
export default {
  name: 'CreditsView',
  data() {
    return {
      activeName: 'first',
      creditShow: true,
      payShow: true,
      kindShow: true,
      autoShow: true,
      needShow: true,
      planShow: true,
      canShow: true,
      secureShow: true,
      partyShow: true
    }
  },
  components: {
    FooterView
  },
  methods: {
    onEarn () {
      this.$router.push({path:'/get-free-credits'})
    },
    onNeedShow () {
      this.needShow = !this.needShow
    },
    onPlanShow () {
      this.planShow = !this.planShow
    },
    onCanShow () {
      this.canShow = !this.canShow
    },
    onSecureShow () {
      this.secureShow = !this.secureShow
    },
    onPartyShow () {
      this.partyShow = !this.partyShow
    },
    openCheckout (avc) {
      console.log('111111111111', avc)
      checkout({ product: avc, return: 'https://www.payhut.me/order-finish.php', currency: 'USD'});
    },
    addcheck100 () {
      document.getElementById('buy_photo_100_1month').addEventListener('click', this.openCheckout('[AS]_AL_PHOTO_ENHANCER_WEB_100C_1M'), false);
      // AL_PHOTO_ENHANCER_WEB_100C_1M 
      // fastspring.builder.add('al-photoai-web-100c-1m')
      // fastspring.builder.checkout()
    },
    addcheck300 () {
      document.getElementById('buy_photo_300_1month').addEventListener('click', this.openCheckout('[AS]_AL_PHOTO_ENHANCER_WEB_300C_1M'), false);
      // console.log('addcheck300')
      // fastspring.builder.add('al-photoai-web-300c-1m')
      // fastspring.builder.checkout()
    },
    addcheck5001month () {
      document.getElementById('buy_photo_500_1month').addEventListener('click', this.openCheckout('[AS]_AL_PHOTO_ENHANCER_WEB_500C_1M'), false);
      // fastspring.builder.add('al-photoai-web-500c-1m')
      // fastspring.builder.checkout()
    },
    addCheckPay50 () {
      // AL_PHOTO_ENHANCER_WEB_50C 
      document.getElementById('buy_photo_50_1month').addEventListener('click', this.openCheckout('[AS]_AL_PHOTO_ENHANCER_WEB_50C'), false);
      // fastspring.builder.add('avclabs-photoai-credits50')
      // fastspring.builder.checkout()
    },
    addCheckPay200 () {
      // AL_PHOTO_ENHANCER_WEB_200C
       document.getElementById('buy_photo_200_1month').addEventListener('click', this.openCheckout('[AS]_AL_PHOTO_ENHANCER_WEB_200C'), false);
      // fastspring.builder.add('avclabs-photoai-credits200')
      // fastspring.builder.checkout()
    },
    addCheckPay500 () {
      // AL_PHOTO_ENHANCER_WEB_500C
      document.getElementById('buy_photo_500_pay').addEventListener('click', this.openCheckout('[AS]_AL_PHOTO_ENHANCER_WEB_500C'), false);
      // fastspring.builder.add('avclabs-photoai-credits500')
      // fastspring.builder.checkout()
    },
    onCreditShow () {
      this.creditShow = !this.creditShow
    },
    onPayShow () {
      this.payShow = !this.payShow
    },
    onKindShow () {
      this.kindShow = !this.kindShow
    },
    onAutoShow () {
      this.autoShow = !this.autoShow
    }
  },

  mounted () {
    
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/scss/module/credits.scss';
</style>
