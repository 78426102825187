<template>
  <div style="width: 100%" class="modal_main" >
    <div class="setting-group" v-for="(item, index) in modelList" :key="index" :class="{modal_bgc:item.customize}">

      <div v-if="item.ai_list[0].name === 'FAST'" class="flexRowBetween modal_setting">
        <div class="flexRowStart">
          <div class="modal_title">
            <el-switch class="modal_switch" v-model="item.customize" @change="onModelChange(item, $event)"></el-switch>
            <h5>{{ $t('Quality Enhance') }}</h5>
            <p>{{ $t('Upscale image resolution and enhance qualit') }}</p>
            <div v-if="item.customize" class="modal_fast_scale">
              <div class="modal_fast_title">
                {{ $t('Please select the picture Scale') }}:
                </div>
              <div v-for="(fast, index) in item.ai_list[0].params" :key="index">
                <el-radio-group class="flexColStart modal_radio_group " v-if="fast.type === 'select'" v-model="fast.default" @change="onModelParamChange(fast, $event)">
                  <el-radio
                    class="modal_fast_radio"
                    v-for="option in fast.values"
                    :key="option.value"
                    :label="option.value">
                    {{option.label}}
                    <!-- <img v-if="option.label === '200%'" class="free_icon" :src="$Image('free-icon.png')" alt=""> -->
                  </el-radio>
                </el-radio-group>
                <div v-else-if="fast.type === 'switch'" class="flexRowBetween modal_switch_ment">
                  <div class="modal_fast_ment">
                    {{ $t('Face Refinement') }}:
                  </div>
                  <el-switch v-model="fast.default" @change="onModelParamChange(fast, $event)" :width="34" inactive-color="#dcdfe6"></el-switch>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="item.customize" class="modal_img">
          <CustomizeImg :isActive='active' :imgUrl='quality1' :enhancerUrl='quality2' :handlelefts="handleleft" :parentClientWidths="parentClientWidth" :aiType="'AI Upscaled'"></CustomizeImg>
        </div>
      </div>

      <div v-if="item.ai_list[0].name === 'DEOLDIFY'" class="flexRowBetween modal_setting">
        <div class="flexRowStart">
          <div class="modal_title">
            <el-switch class="modal_switch" v-model="item.customize" @change="onModelChange(item, $event)"></el-switch>
            <h5>{{ $t('Photo Colorize') }}</h5>
            <p>{{ $t('Bring colors into the B&W photos') }}</p>
            <div v-if="item.customize">
              <div class="modal_fast_title">
                {{$t('Render Factor')}}:
              </div>
              <div v-for="(fast, index) in item.ai_list[0].params" :key="index">
                <el-slider v-if="fast.type === 'slider'" :min="fast.values.min" :max="fast.values.max" v-model="fast.default" @input="onModelParamChange(fast, $event)" :show-tooltip="false" color="#3185ff" style="margin-top: 8px"></el-slider>
              </div>
            </div>
          </div>
        </div>
        <div v-if="item.customize" class="modal_img">
          <CustomizeImg :isActive='active' :imgUrl='colorize1' :enhancerUrl='colorize2' :handlelefts="handleleft" :parentClientWidths="parentClientWidth" :aiType="'AI Colorized'"></CustomizeImg>
        </div>
      </div>

      <div v-if="item.ai_list[0].name === 'DENOISING'" class="flexRowBetween modal_setting">
        <div class="flexRowStart">
          <div class="modal_title">
            <el-switch class="modal_switch" v-model="item.customize" @change="onModelChange(item, $event)"></el-switch>
            <h5>{{ $t('Noise Remove') }}</h5>
            <p>{{ $t('Remove noise from photo to make it clearer') }}</p>

          </div>
        </div>
        <div v-if="item.customize" class="modal_img">
          <CustomizeImg :isActive='active' :imgUrl='noise1' :enhancerUrl='noise2' :handlelefts="handleleft" :parentClientWidths="parentClientWidth" :aiType="'AI Denoised'"></CustomizeImg>
        </div>
      </div>

      <div v-if="item.ai_list[0].name === 'RMBG'" class="flexRowBetween modal_setting">
        <div class="flexRowStart">
          <div class="modal_title">
            <el-switch class="modal_switch" v-model="item.customize" @change="onModelChange(item, $event)"></el-switch>
            <h5>{{ $t('Background Remove') }}</h5>
            <p>{{ $t('Turn the image into transparent background') }}</p>

          </div>
        </div>
        <div v-if="item.customize" class="modal_img">
          <CustomizeImg :isActive='active' :imgUrl='background1' :enhancerUrl='background2' :handlelefts="handleleft" :parentClientWidths="parentClientWidth" :aiType="'AI RemoveBG'"></CustomizeImg>
        </div>
      </div>

      <div v-if="item.ai_list[0].name === 'COLCALIBRATION'" class="flexRowBetween modal_setting">
        <div class="flexRowStart">
          <div class="modal_title">
            <el-switch class="modal_switch" v-model="item.customize" @change="onModelChange(item, $event)"></el-switch>
            <h5>{{ $t('Color Calibrate') }}</h5>
            <p>{{ $t('Calibrate color to achieve natural color rendition') }}</p>

          </div>
        </div>
        <div v-if="item.customize" class="modal_img">
          <CustomizeImg :isActive='active' :imgUrl='calibrate1' :enhancerUrl='calibrate2' :handlelefts="handleleft" :parentClientWidths="parentClientWidth" :aiType="'AI Calibrated'"></CustomizeImg>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CustomizeImg from '../common/CustomizeImg.vue'
import * as Mutations from '@/store/mutations.js'
import bus from '@/assets/js/bus'

export default {
  name: 'model-setting',
  data () {
    return {
      exportPath: '',
      paramsList: [],
      value1: false,
      handleleft: 880/2,
      parentClientWidth: 880,
      noise1: 'model3_noise_remove1.jpg',
      noise2: 'model3_noise_remove2.jpg',
      quality1: 'model1_quality_enhance1.jpg',
      quality2: 'model1_quality_enhance2.jpg',
      background1: 'model4_background_remove1.jpg',
      background2: 'model4_background_remove2.jpg',
      colorize1: 'model5_photo_colorize1.jpg',
      colorize2: 'model5_photo_colorize2.jpg',
      calibrate1: 'model6_color_calibrate1.jpg',
      calibrate2: 'model6_color_calibrate2.jpg',
      active: true
    }
  },
  components: {
    CustomizeImg
  },
  methods: {
    onModelChange (item) {
      console.log('item1111111', item)
      this.value1 = item.customize
      //更新当前选择的模型
      this.setCurrentModel()
    },
    onModelParamChange (item) {
      // 保存当前选中值在model中
      // item.default = v
      console.log('item', item)
      //更新当前选择的模型
      this.setCurrentModel()
    },
    async setCurrentModel () {
      let info = {
        label: '',
        ai_list: []
      }
      let label_list = []
      this.modelList.forEach(item => {
        if (item.customize) {
          this.$store.commit(Mutations.setCustomize, false)
          if (item.label === 'Photo Denoiser') {
            label_list.push('Noise Remove')
          }
          if (item.label === 'Photo Sharpener') {
            label_list.push('Quality Enhance')
          }
          if (item.label === 'Background Remover') {
            label_list.push('Background Remove')
          }
          if (item.label === 'Photo Colorizer') {
            label_list.push('Photo Colorizer')
          }
          if (item.label === 'Photo Calibrater') {
            label_list.push('Color Calibrate')
          }
          let la = label_list.join(',')
          info.label = la
          const { ai_list } = item
          ai_list.forEach(item => {
            const { name, params } = item
            let infoParams = {}
            params.forEach(item => {
              const { name } = item
              infoParams[name] = item.default
            })
            info.ai_list.push({
              name,
              params: infoParams
            })
          })
          this.$store.commit(Mutations.setCurrentModelInfo, info)
        } else {
          // this.$store.commit(Mutations.setCustomize, true)
        }
      })
      // 增强按钮是否disabled
      const idm = this.modelList.map(image => image.customize)
      if (idm.indexOf(true) === -1) {
        this.$store.commit(Mutations.setCustomize, true)
      }
    },
    onSelect (e) {
      this.modelList.map(item => {
        if (e === 'BGRemoving' && item.label === 'Background Remover') {
          item.customize = true
        }
        if (e === 'ColorCalibrating' && item.label === 'Photo Calibrater') {
          item.customize = true
        }
        if (e === 'Colorizing' && item.label === 'Photo Colorizer') {
          item.customize = true
        }
        if (e === 'Denoising' && item.label === 'Photo Denoiser') {
          item.customize = true
        }
        if (e === 'FaceRetouching' && item.label === 'Photo Sharpener') {
          item.customize = true
        }
      })
      this.setCurrentModel()
    }
  },
  computed: {
    ...mapState(['modelList', 'currentModelInfo', 'userId', 'WorkspaceState'])
  },
  mounted () {
    bus.$on('emitSelect', this.onSelect)
    // this.modelList && this.modelList.length !== 0 && this.onModelChange(this.currentModelInfo.label)
  },
  watch: {
    // 当vuex还未存入modelList的时候 在mounted中调用onModelChange太早 所以需要在watch中调用
    // 现在修改为在App.vue中先发送请求获取modelList存入vuex中 此时在mounted中调用onModelChange即可
    // modelList (nv) {
    //   if (nv && nv.length !== 0) {
    //     this.onModelChange(this.currentModelInfo.label)
    //   }
    // }
  },
}
</script>

<style scoped lang="scss">
  @import "@/assets/scss/module/module-model-setting.scss";
</style>
