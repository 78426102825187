<template>
  <div class="customize_img_editor twentytwenty-horizontal" :class="{modelcustomize: isActive}">
    <div id="productdiv" class="Original_img Original_fg" v-on:mousemove="MousBtns" >
      <img :src="$Image(imgUrl)" :style="{'clip': ('rect' + '(' + '0px,' + handleleft + 'px,' + ClientWidths + 'px,' + '0px)')}" />
      <img :src="$Image(enhancerUrl)" class="bg-color bg-image" :style="{'clip': 'rect' + '(' + '0px,' + ClientWidths + 'px,' + '1240px,' + handleleft + 'px)'}" />

    </div>
    <div class="twentytwenty-handle" :style="{'left': handleleft + 'px'}" v-on:touchmove="touchBtns">
      <span class="twentytwenty-left-arrow"></span>
      <span class="twentytwenty-right-arrow"></span>
    </div>
    <div v-if="!isActive" class="customize_before customize_t">
      {{$t('Before')}}
    </div>
    <div v-else class="customize_before customize_t_m">
      {{$t('Original')}}
    </div>
    <div v-if="!isActive" class="customize_after customize_t">
      {{$t('After')}}
    </div>
    <div v-else class="customize_after customize_t_m">
      {{$t(aiType)}}
      
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      handleleft: this.handlelefts,
      ClientWidths: this.parentClientWidths
    }
  },
  props: ['imgUrl', 'enhancerUrl', 'handlelefts', 'parentClientWidths', 'isActive', 'aiType'],
  methods: {
    MousBtns (e) {
      let num = e.clientX - (e.clientX - e.layerX)
      this.handleleft = num
    },
    touchBtns (e) {
      // console.log('11111111', e.targetTouches[0])
      let ev = e || window.event
      let touchX = ev.targetTouches[0].clientX

      window.onresize = () => {
        this.handleleft = touchX
      }
  
      this.handleleft = touchX
    }
  },
  mounted () {
    window.onresize = () => {
      let DivWidth = document.getElementById('productdiv')
      // console.log('productdiv:', DivWidth)
      this.ClientWidths = DivWidth ? DivWidth.offsetWidth : this.parentClientWidths
      this.handleleft = DivWidth ? DivWidth.offsetWidth / 2 : this.handlelefts
    }
 
  }
      
}
</script>