<template>
  <div class="footers_s">

    <div class="flexRowCenter footers_a">
      <div class="footer_avc">
          AVC·AI
          <p>
            {{ $t('We develop AI-powered image and video software that help users improve the image and video quality with ease.') }}
          </p>
          <div class="footer_avc_icon flexRowStart">
            <div @click="onFacebook" class="footer_avc_f"></div>
            <div @click="onTwitter" class="footer_avc_w"></div>
            <div @click="onYoutube" class="footer_avc_y"></div>
          </div>
      </div>

      <div class="flexAlignStart footer_company">
        <div class="single-widget mar_left">
          <h3>{{ $t('Company')}}</h3>

          <ul class="import-link">
            <li>
              <a @click="onAbout">
                <i class="el-icon-d-arrow-right"></i>
                {{ $t('About Us')}}
              </a>
            </li>
            <li>
              <a @click="onDownload">
                <i class="el-icon-d-arrow-right"></i>
                {{ $t('Download App')}}
              </a>
            </li>
            <li>
              <a @click="onOnline">
                <i class="el-icon-d-arrow-right"></i>
                {{ $t('Online Store')}}
              </a>
            </li>
            <!-- <li>
              <a href="https://www.avclabs.com/sitemap.html">
                <i class="el-icon-d-arrow-right"></i>
                {{ $t('Sitemap')}}
              </a>
            </li> -->
          </ul>
        </div>

        <div class="single-widget mar_left">
          <h3>{{ $t('AI Solutions')}}</h3>
          <ul class="import-link">
            <li>
              <a @click="onEnhancer">
                <i class="el-icon-d-arrow-right"></i>
                {{ $t('Video Enhancer AI')}}
              </a>
            </li>
            <li>
              <a @click="onPhotoEnhancer">
                <i class="el-icon-d-arrow-right"></i>
                {{ $t('Photo Enhancer AI')}}
              </a>
            </li>
            <li>
              <a @click="onRemove">
                <i class="el-icon-d-arrow-right"></i>
                {{ $t('Video BG Remove')}}
              </a>
            </li>
          </ul>
        </div>

        <div class="single-widget">
          <h3>{{ $t('Support')}}</h3>
          <ul class="import-link">
            <li>
              <a @click="onSupport">
                <i class="el-icon-d-arrow-right"></i>
                {{ $t('Support')}}
              </a>
            </li>
            <li>
              <a @click="onFAQ">
                <i class="el-icon-d-arrow-right"></i>
                {{ $t('FAQ')}}
              </a>
            </li>
            <li>
              <a @click="onContact">
                <i class="el-icon-d-arrow-right"></i>
                {{ $t('Contact')}}
              </a>
            </li>
          </ul>
        </div>

      </div>

    </div>
    
    <div class="flexRowBetween footers_c">
      <div class="footer_copyright">
        {{ $t('Copyright © 2024 Anvsoft inc. All Rights Reserved') }}
      </div>
       <div class="footer_copyright footer_copyright_privacy" >
        <span @click="onPrivacy">
          {{ $t('Privacy Policy') }}
        </span>
          -
        <span @click="onTerms">
          {{ $t('Terms & Conditions') }}
        </span>
      </div>
    </div>
  </div>
</template>


<script>
import { getCookie } from '@/assets/js/utils/cookie'
export default {
  name: 'footers',
  data() {
    return {
    }
  },
  methods: {
    onFacebook () {
      window.open('https://www.facebook.com/AVCLabs.Official2022')
    },
    onTwitter () {
      const lang = getCookie('lang')
      switch (lang) {
        case 'ja':
          window.open('https://twitter.com/AVCLabsJapan')
          break
        default:
          window.open('https://twitter.com/AVCLabsofficial')
          break
      }
      // window.open('https://twitter.com/AVCLabsofficial')
    },
    onYoutube () {
      const lang = getCookie('lang')
      switch (lang) {
        case 'ja':
          window.open('https://www.youtube.com/channel/UCbWJbV_pG5n1Hv6EQqnUaCw')
          break
        default:
          window.open('https://www.youtube.com/channel/UCxBfdONiMTIE_oB2DoKkthg')
          break
      }
    },
    onTerms () {
      const lang = getCookie('lang')
      switch (lang) {
        case 'en':
          window.open('https://www.avclabs.com/terms-of-use.html')
          break
        case 'de':
          window.open('https://www.avclabs.de/terms-of-use.html')
          break
        case 'fr':
          window.open('https://www.avclabs.com/fr/terms-of-use.html')
          break
        case 'es':
          window.open('https://www.avclabs.com/terms-of-use.html')
          break
        case 'ja':
          window.open('https://www.avclabs.jp/terms-of-use.html')
          break
        default:
          window.open('https://www.avclabs.com/terms-of-use.html')
          break
      }
    },
    onPrivacy () {
      const lang = getCookie('lang')
      switch (lang) {
        case 'en':
          window.open('https://www.avclabs.com/privacy-policy.html')
          break
        case 'de':
          window.open('https://www.avclabs.de/privacy-policy.html')
          break
        case 'fr':
          window.open('https://www.avclabs.com/fr/privacy-policy.html')
          break
        case 'es':
          window.open('https://www.avclabs.com/privacy-policy.html')
          break
        case 'ja':
          window.open('https://www.avclabs.jp/privacy-policy.html')
          break
        default:
          window.open('https://www.avclabs.com/privacy-policy.html')
          break
      }
    },
    onContact () {
      const lang = getCookie('lang')
      switch (lang) {
        case 'en':
          window.open('https://www.avclabs.com/contact.html')
          break
        case 'de':
          window.open('https://www.avclabs.de/contact.html')
          break
        case 'fr':
          window.open('https://www.avclabs.com/contact.html')
          break
        case 'es':
          window.open('https://www.avclabs.com/contact.html')
          break
        case 'ja':
          window.open('https://www.avclabs.jp/contact.html')
          break
        default:
          window.open('https://www.avclabs.com/contact.html')
          break
      }
    },
    onFAQ () {
      const lang = getCookie('lang')
      switch (lang) {
        case 'en':
          window.open('https://www.avclabs.com/faq.html')
          break
        case 'de':
          window.open('https://www.avclabs.de/faq.html')
          break
        case 'fr':
          window.open('https://www.avclabs.com/faq.html')
          break
        case 'es':
          window.open('https://www.avclabs.com/faq.html')
          break
        case 'ja':
          window.open('https://www.avclabs.jp/faq.html')
          break
        default:
          window.open('https://www.avclabs.com/faq.html')
          break
      }
    },
    onSupport () {
            const lang = getCookie('lang')
      console.log('lang', lang)
      switch (lang) {
        case 'en':
          window.open('https://www.avclabs.com/support.html')
          break
        case 'de':
          window.open('https://www.avclabs.de/support.html')
          break
        case 'fr':
          window.open('https://www.avclabs.com/support.html')
          break
        case 'es':
          window.open('https://www.avclabs.com/support.html')
          break
        case 'ja':
          window.open('https://www.avclabs.jp/support.html')
          break
        default:
          window.open('https://www.avclabs.com/support.html')
          break
      }
    },
    onRemove () {
      window.open('https://app.avclabs.com/')
    },
    onPhotoEnhancer () {
      const lang = getCookie('lang')
      console.log('lang', lang)
      switch (lang) {
        case 'en':
          window.open('https://www.avclabs.com/photo-enhancer-ai.html')
          break
        case 'de':
          window.open('https://www.avclabs.de/photo-enhancer-ai.html')
          break
        case 'fr':
          window.open('https://www.avclabs.com/fr/photo-enhancer-ai.html')
          break
        case 'es':
          window.open('https://www.avclabs.com/es/photo-enhancer-ai.html')
          break
        case 'ja':
          window.open('https://www.avclabs.jp/photo-enhancer-ai.html')
          break
        default:
          window.open('https://www.avclabs.com/photo-enhancer-ai.html')
          break
      }
    },
    onEnhancer () {
      const lang = getCookie('lang')
      console.log('lang', lang)
      switch (lang) {
        case 'en':
          window.open('https://www.avclabs.com/video-enhancer-ai.html')
          break
        case 'de':
          window.open('https://www.avclabs.de/video-enhancer-ai.html')
          break
        case 'fr':
          window.open('https://www.avclabs.com/fr/video-enhancer-ai.html')
          break
        case 'es':
          window.open('https://www.avclabs.com/es/video-enhancer-ai.html')
          break
        case 'ja':
          window.open('https://www.avclabs.jp/video-enhancer-ai.html')
          break
        default:
          window.open('https://www.avclabs.com/video-enhancer-ai.html')
          break
      }
    },
    onOnline () {
      const lang = getCookie('lang')
      console.log('lang', lang)
      switch (lang) {
        case 'en':
          window.open('https://www.avclabs.com/purchase.html')
          break
        case 'de':
          window.open('https://www.avclabs.de/purchase.html')
          break
        case 'fr':
          window.open('https://www.avclabs.com/purchase.html')
          break
        case 'es':
          window.open('https://www.avclabs.com/purchase.html')
          break
        case 'ja':
          window.open('https://www.avclabs.jp/purchase.html')
          break
        default:
          window.open('https://www.avclabs.com/purchase.html')
          break
      }
    },
    onDownload () {
      const lang = getCookie('lang')
      console.log('lang', lang)
      switch (lang) {
        case 'en':
          window.open('https://www.avclabs.com/download.html')
          break
        case 'de':
          window.open('https://www.avclabs.de/download.html')
          break
        case 'fr':
          window.open('https://www.avclabs.com/download.html')
          break
        case 'es':
          window.open('https://www.avclabs.com/download.html')
          break
        case 'ja':
          window.open('https://www.avclabs.jp/download.html')
          break
        default:
          window.open('https://www.avclabs.com/download.html')
          break
      }
    },
    onAbout () {
      const lang = getCookie('lang')
      console.log('lang', lang)
      switch (lang) {
        case 'en':
          window.open('https://www.avclabs.com/about-us.html')
          break
        case 'de':
          window.open('https://www.avclabs.de/about-us.html')
          break
        case 'fr':
          window.open('https://www.avclabs.com/about-us.html')
          break
        case 'es':
          window.open('https://www.avclabs.com/about-us.html')
          break
        case 'ja':
          window.open('https://www.avclabs.jp/about-us.html')
          break
        default:
          window.open('https://www.avclabs.com/about-us.html')
          break
      }
    }
  },
  mounted() {
  },
}
</script>

<style scoped lang="scss">
  @import "@/assets/scss/module/module-footer.scss";
</style>
