import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Axios from 'axios'
import VModal from 'vue-js-modal'
import VueI18n from 'vue-i18n'
import * as Mutations from '@/store/mutations.js'
import './plugins/element.js'
import './assets/scss/common/index.scss'
import './assets/scss/module/index.scss'
import { setLangCookie } from '@/assets/js/lang.js'
import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)
Vue.config.productionTip = false
Vue.prototype.$Image = (imgName) => {
  return require(`./assets/images/${imgName}`)
}
Vue.prototype.axios = Axios
Vue.prototype.$AxiosGet = Axios.get
Vue.prototype.$AxiosPost = Axios.post

Vue.use(VModal, { dynamic: true })
Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'en',
  messages: {
    'en': require('@/localize/en.json'),
    'de': require('@/localize/de.json'),
    'fr': require('@/localize/fr.json'),
    'ja': require('@/localize/ja.json'),
    'es': require('@/localize/es.json')
  },
  silentTranslationWarn: true
})

// eslint-disable-next-line no-unexpected-multiline
;(async function () {
  setLangCookie()
  const res = await Axios.get('/config.json')
  store.commit(Mutations.setConfig, res?.data)
  Vue.prototype.STS_URL = res.data?.STS_URL
  Axios.defaults.baseURL = res.data?.BASE_URL

  new Vue({
    router,
    store,
    i18n,
    mounted () {
      document.dispatchEvent( new Event( 'render-event' ))
    },
    render: h => h(App)
  }).$mount('#app')
})();
  

