<template>
<div class="fullContent" style="position: relative;height:100%;">
  <div class="upscalling_main">

    <div class="upload-view upload_bgc flexColCenter">

      <div class="flexRowCenter mobile_col" style="padding: 58px 0 30px">
        <div class="flexColStart photo_title">
          <div class="photo_title_g">
            {{ $t('Colorize Black&White Pictures to Bring them New Life') }}
           
          </div>
          <div class="photo_title_t">
            {{ $t('How to colorize your old family snaps and historical pictures to give them a new lease of life? With AI Photo Colorizer, turning black and white photos into colors would be automatic with one click.') }}
         
          </div>
        </div>
        <img :src="$Image('colorizing_top_banner.png')" alt="">
      </div>
      
      <div class="flexColCenter photo_upload " ref='select_frame'  ondragstart="return false">
        <p class="photo_upload_d">
          {{ $t('Drag image here or click to upload.') }}
          </p>
        <div class="upload_drag flexRowCenter">
          <div class="config_upload flexRowStart">
            <div class="click_upload flexRowCenter" @click="onChoose">
              <img :src="$Image('upload_image.png')" alt="">
              <span>
                {{ $t('Upload Image') }}
              </span>
            </div>
          </div>
        </div>
        <p class="photo_upload_l" @click="onDownloadMore">
          {{ $t('Download App to unlock more features') }}   ></p>
      </div>

    </div>
  
    <!-- img1 -->
    <div class="flexColCenter upload-view form_image">
      <div class="form_image_u">
        {{ $t('AI Picture Colorizer: Turn Black and White Pictures into Color') }}
      </div>
            <p class="form_image_a mar_tb">
              {{ $t('Black-and-white photos are timeless. The old family and childhood photos present treasured memories of the past. Historical photos are records of the big events and living visages of ancestors. Colorizing black-and-white photos would be meaningful.') }}
  
      </p>
            <p class="form_image_a">
              {{ $t('Bringing color to a black-and-white photo in Photoshop would be time-consuming, Photoshop skills are required as well. Because it offers coloring options that need a few hours to learn how to control.') }}
   
      </p>
            <p class="form_image_a mar_tb">
              {{ $t('Thanks to artificial intelligence, colorizing a photo can be done automatically.') }}
    
      </p>

      <CustomizeImg :imgUrl='img1' :enhancerUrl='img2' :handlelefts="handleleft" :parentClientWidths="parentClientWidth"></CustomizeImg>

      <div class="flexRowCenter mar_t">
        <div class="config_upload flexRowStart" @click="onTryFree">
          <div class="click_upload click_btn_w flexRowCenter">
            <span>
              {{ $t('Try it for free') }}
            </span>
          </div>
        </div>
        <div class="google_play_btn flexRowStart">
          <div class="click_google_play download_w flexRowCenter" @click="onDownload">
            <div class="download_icon"></div>
            <span>
              {{ $t('Download for Win&Mac') }}
            </span>
          </div>
        </div>
      </div>

    </div>

    <!-- img2 -->
    <div class="flexColCenter upload-view form_image">
      <div class="form_image_u">
        {{ $t('Colorize Old Childhood Photos') }}
        
      </div>
      <p class="form_image_a mar_tb" style="text-align: center;">
        {{ $t('Colorize your childhood photos to look back to the happy times and memories of your childhood.') }}
        
      </p>

      <CustomizeImg :imgUrl='img3' :enhancerUrl='img4' :handlelefts="handleleft" :parentClientWidths="parentClientWidth"></CustomizeImg>

      <div class="flexRowCenter mar_t">
        <div class="config_upload flexRowStart" @click="onTryFree">
          <div class="click_upload click_btn_w flexRowCenter">
            <span>
              {{ $t('Try it for free') }}
            </span>
          </div>
        </div>
        <div class="google_play_btn flexRowStart">
          <div class="click_google_play download_w flexRowCenter" @click="onDownload">
            <div class="download_icon"></div>
            <span>
              {{ $t('Download for Win&Mac') }}
            </span>
          </div>
        </div>
      </div>


    </div>


    <!-- img3 -->
    <div class="flexColCenter upload-view form_image">
      <div class="form_image_u">
        {{ $t('Colorize Old Family Photos') }}
        
      </div>
      <p class="form_image_a mar_tb" style="text-align: center;">
        {{ $t('Colorize your family photos to revoke the treasured memories with your family members and make a great surprise gift.') }}
        
      </p>

      <CustomizeImg :imgUrl='img5' :enhancerUrl='img6' :handlelefts="handleleft" :parentClientWidths="parentClientWidth"></CustomizeImg>

      <div class="flexRowCenter mar_t">
        <div class="config_upload flexRowStart" @click="onTryFree">
          <div class="click_upload click_btn_w flexRowCenter">
            <span>
              {{ $t('Try it for free') }}
            </span>
          </div>
        </div>
        <div class="google_play_btn flexRowStart">
          <div class="click_google_play download_w flexRowCenter" @click="onDownload">
            <div class="download_icon"></div>
            <span>
              {{ $t('Download for Win&Mac') }}
            </span>
          </div>
        </div>
      </div>


    </div>

    <!-- img4 -->
    <div class="flexColCenter upload-view form_image last_mb">
      <div class="form_image_u">
        {{ $t('Colorize Old Historical Photos') }}
        
      </div>
      <p class="form_image_a mar_tb" style="text-align: center;">
        {{ $t('Colorize historical photos to make historical events truly come alive and change the way you imagine the past.') }}
       
      </p>

      <CustomizeImg :imgUrl='img7' :enhancerUrl='img8' :handlelefts="handleleft" :parentClientWidths="parentClientWidth"></CustomizeImg>

      <div class="flexRowCenter mar_t">
        <div class="config_upload flexRowStart" @click="onTryFree">
          <div class="click_upload click_btn_w flexRowCenter">
            <span>
              {{ $t('Try it for free') }}
            </span>
          </div>
        </div>
        <div class="google_play_btn flexRowStart">
          <div class="click_google_play download_w flexRowCenter" @click="onDownload">
            <div class="download_icon"></div>
            <span>
              {{ $t('Download for Win&Mac') }}
            </span>
          </div>
        </div>
      </div>


    </div>
    <FooterView></FooterView>
  </div>
</div>

</template>



<!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NK52HVJ');</script>
<!-- End Google Tag Manager -->

<script>
import CustomizeImg from '../common/CustomizeImg.vue'
import FooterView from '../common/Footer.vue'
import bus from '@/assets/js/bus'
import { getCookie } from '@/assets/js/utils/cookie'

export default {
  name: 'Colorizing',
  metaInfo: {
    title: 'AI Photo Restorer and Colorizer that Turns Your Old Black and White Images into Color Online',
    meta: [
      {
        name: 'description',
        content: 'AI-powered old photo restoration online tool that brings your old pictures back to life, and turns black and white photos to color online.'
      },
      {
        name: 'keywords',
        content: 'old photo restoration online, restore old photo with ai, convert black and white photo to color online'
      }
    ]
  },
  data() {
    return {
      handleleft: 926/2,
      parentClientWidth: 926,
      img1: 'colorizing_natural1.jpg',
      img2: 'colorizing_natural2.jpg',
      img3: 'colorizing_childhood1.jpg',
      img4: 'colorizing_childhood2.jpg',
      img5: 'colorizing_family1.jpg',
      img6: 'colorizing_family2.jpg',
      img7: 'colorizing_historical1.jpg',
      img8: 'colorizing_historical2.jpg'
    }
  },
  components: {
    CustomizeImg,
    FooterView
  },
  methods: {
    onDownloadMore () {
      const lang = getCookie('lang')
      switch (lang) {
        case 'en':
          window.open('https://www.avclabs.com/download.html')
          break
        case 'de':
          window.open('https://www.avclabs.de/download.html')
          break
        case 'fr':
          window.open('https://www.avclabs.com/download.html')
          break
        case 'es':
          window.open('https://www.avclabs.com/download.html')
          break
        case 'ja':
          window.open('https://www.avclabs.jp/download.html')
          break
        default:
          window.open('https://www.avclabs.com/download.html')
          break
      }
    },
    onTryFree () {
      this.$router.push({path:'/'})
    },
    onChoose () {
      bus.$emit('uploadImage')
      bus.$emit('emitSelect', 'Colorizing')
      this.$router.push({path:'/'})
    },
    onDownload () {
      window.location.href = 'https://www.avclabs.com/download.html'
    }
  },

  mounted () {
    this.$refs.select_frame.ondragleave = (e) => {
        e.preventDefault()
    }
    this.$refs.select_frame.ondrop = (e) => {
        e.preventDefault()
        const data = e.dataTransfer.files
        if (data.length < 1) {
            return
        }
        // console.log('dataaaaaaa', data)
        bus.$emit('onGetFile', data)
        this.$router.push({path:'/'})
    }
    this.$refs.select_frame.ondragenter = (e) => {
        e.preventDefault()
    }
    this.$refs.select_frame.ondragover = (e) => {
        e.preventDefault()
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/scss/module/upload-view.scss';
</style>
