/* eslint-disable no-unused-vars */
(function (window) {
  'use strict'
  
  const ScaleType = {
    NoChange: 'Not Change Scale',
    Fit: 'Scale Fit',
    ActualSize: 'Scale Keep Actual Size'
  }
  window.ScaleType = ScaleType

  function ImageViewer (canvasId, imageUrl, loadCallback, enhanceLoadCallback) {
    var self = this

    self.imageLoadCallback = loadCallback
    self.enhanceImageLoadCallback = enhanceLoadCallback

    // canvas
    var canvas = document.getElementById(canvasId),
      context = canvas.getContext('2d'),

      // dirty state
      dirty = true,

      // flag to stop render loop
      stopRendering = false,

      // image scale
      imgScale = 1,
      eimgScale = 1,
      scale = 1,
      eScale = 1,
      scaleStep = 0.1,
      zoom = 1,

      // image centre (scroll offset)
      centre = { x: 0, y: 0 },

      // drawing settings
      defaultSplitLineWidth = 5,

      // keeping track of event handling
      events = [],

      // Input handling
      // active element (mainly) used for dragging
      activeMoveElement = centre,
      // track state of left mouse button (even outside the canvas)
      leftMouseButtonDown = false,
      // keep last mouse position to calculate drag distance
      mouseLastPos = null
      
    // image
    this.image = new Image()
    this.enhancedImage = new Image()
  
    // 是否画增强图
    this.isDrawEnhance = false

    // 默认偏移
    this.drawRect = null
  
    // 分割线位置，默认Canvas中间
    this.splitPosition = 0.5

    // 分割线矩形
    this.splitLineRect = null

    // 是否是移动分割线
    this.isMoveLine = false

    // 是否是缩小
    this.isNeedChnageCentre = false

    // 是否是Fit模式
    this.isFit = true

    // 是否Canvas尺寸变化了
    this.isCanvasSizeChange = false

    function LimitsWidth (left, canvasWidth, imageWidth) {
      if (canvasWidth - imageWidth >= 0) {
        left = (canvasWidth - imageWidth) / 2
        return left
      } else {
        if (left > 0) {
          left = 0
        }
        if (left < canvasWidth - imageWidth) {
          left = canvasWidth - imageWidth
        }
        return left
      }
    }

    // x, y: 新的translate位置
    function checkMove (x, y) {
      const dh = self.drawRect.height
      const dw = self.drawRect.width
      const ch = canvas.height
      const cw = canvas.width
      const ox = self.drawRect.x
      const oy = self.drawRect.y

      // console.log(dh, dw, ch, cw, ox, oy, x, y)
      const left = ox + x
      const top = oy + y
      const calLeft = LimitsWidth(left, cw, dw)
      const calTop = LimitsWidth(top, ch, dh)

      const moveX = calLeft - ox
      const moveY = calTop - oy

      const isNeedMove = moveX !== centre.x || moveY !== centre.y
      // console.log('isNeedMove:', isNeedMove)
      return { isNeedMove, moveX, moveY }
    }

    function calScale (iw, ih) {
      return Math.min(canvas.width / iw, canvas.height / ih)
    }

    function calImgScale () {
      if (self.isDrawEnhance) {
        eScale = self.isFit ? calScale(self.enhancedImage.width, self.enhancedImage.height) : 1
        scale = eScale * Math.min(self.enhancedImage.width / self.image.width, self.enhancedImage.height / self.image.height)
      } else {
        eScale = 1
        scale = self.isFit ? calScale(self.image.width, self.image.height) : 1
      }

      imgScale = scale
      scale *= zoom

      eimgScale = eScale  
      eScale *= zoom
    }

    function onEnhancedImageLoad () {
      console.warn(`onEnhancedImageLoad: height: ${self.enhancedImage.height}, width: ${self.enhancedImage.width}`)
      self.isDrawEnhance = true

      self.enhanceImageLoadCallback && self.enhanceImageLoadCallback()

      calImgScale()

      // 分割线默认画到canvas中间
      self.splitPosition = 0.5
      self.resetPosition()
    }

    function onImageLoad () {
      // set scale to use as much space inside the canvas as possible
      console.warn(`onImageLoad: ch: ${canvas.height}, ih: ${self.image.height}, cw: ${canvas.width}, iw: ${self.image.width}`)

      self.imageLoadCallback && self.imageLoadCallback({width: self.image.width, height: self.image.height})

      calImgScale()
      self.resetPosition()

      // start new render loop
      render()
    }

    // 设置原图
    this.setImage = function (img) {
      // console.warn('setImage:', img)
      if (img) {
        this.imageLoadCallback = loadCallback
        this.image.src = img

        this.isDrawEnhance = false
        this.drawRect = null
        this.splitPosition = 0.5
        this.splitLineRect = null
        this.isFit = true
      }
    }

    // 设置增强图
    this.setEnhancedImage = function (eimg) {
      // console.warn('setEnhancedImage:', eimg)
      if (eimg) {
        this.enhancedImage.src = eimg
      } else {
        this.isDrawEnhance = false
        this.splitLineRect = null
        dirty = true
      }
    }

    this.getImageScale = function () {
      return this.isDrawEnhance ? eScale : scale
    }

    this.forceRender = function (options) {
      // console.log('[viewer] forceRender with options:', `${canvas.width}x${canvas.height}`, options)

      if (options) {
        // eslint-disable-next-line no-prototype-builtins
        if (options.hasOwnProperty('scaleType')) {
          const { scaleType } = options
          self.isFit = scaleType === ScaleType.Fit
          // console.warn(self.isFit ? 'fit.' : 'actual size.')
      
          zoom = 1
          
          calImgScale()
          self.resetPosition()
        } 
        
        if (options.isCanvasSizeChange) {
          // 拖动引起的重绘
          this.isCanvasSizeChange = true
          if (this.isFit) {
            calImgScale()
          }
        }
      }
      
      // console.log('[viewer] forceRender scale:', scale, eScale)
      dirty = true
    }

    this.resetPosition = function () {
      centre = { x: 0, y: 0}
      dirty = true
    }

    this.setZoom = function (zoomTo) {
      // 基于当前显示大小放缩
      // scale = imgScale * zoomTo
      // eScale = eimgScale * zoomTo

      // this.isNeedChnageCentre = zoomTo < zoom

      // zoom = zoomTo
      // dirty = true

      //基于图片大小放缩
      if (self.isDrawEnhance) {
        eScale = zoomTo
        scale = eScale * Math.min(self.enhancedImage.width / self.image.width, self.enhancedImage.height / self.image.height)
      } else {
        scale = zoomTo
        eScale = scale
      }

      //缩小时需要调整中心点，避免出现四周空白情况
      this.isNeedChnageCentre = zoomTo < zoom

      zoom = zoomTo
      dirty = true;
    }

    function drawImageWithRect (ctx, img, fromRect, toRect) {
      ctx.drawImage(img, fromRect.x, fromRect.y, fromRect.width, fromRect.height, toRect.x, toRect.y, toRect.width, toRect.height)
    }

    function render () {
      // only re-render if dirty
      if (dirty) {
        console.warn('[viewer] dirty, render.')
        // console.group('dirty draw.')
        dirty = false

        var ctx = context
        // clear canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        ctx.save()

        // console.log('[viewer] render, scale:', scale, eScale, centre.x, centre.y)

        const ch = canvas.height
        const cw = canvas.width
        const ih = self.image.height
        const iw = self.image.width
        const dw = iw * scale
        const dh = ih * scale
        
        const centreX = (cw / 2) - (iw / 2) * scale
        const centreY = (ch / 2) - (ih / 2) * scale

        // 当前位置
        const cx = self.drawRect ? self.drawRect.x : centreX
        const cy = self.drawRect ? self.drawRect.y : centreY
        
        let dx = centreX
        let dy = centreY

        if (self.isCanvasSizeChange) {
          self.isCanvasSizeChange = false
          if (self.drawRect) { 
            // Canvas宽增大
            if (cw > self.drawRect.canvasWidth) {
              if (cw < dw) {
                if (cw < cx + dw) {
                  self.isNeedChnageCentre = true
                } else {
                  centre.x = 0
                }
              }
            }

            // Canvas高增大
            if (ch > self.drawRect.canvasHeight) {
              if (ch < dh) {
                if (ch < cy + dh) {
                  self.isNeedChnageCentre = true
                } else {
                  centre.y = 0
                }
              }
            }
          }
        }

        if (self.isNeedChnageCentre) {
          //console.error('zoom out draw.')
          self.isNeedChnageCentre = false
          //console.log('before zoom:', centre)

          const left = dx - centre.x
          const top = dy - centre.y
          // 计算合适的位置
          const calLeft = LimitsWidth(left, cw, dw)
          const calTop = LimitsWidth(top, ch, dh)

          // 将centre向正确位置偏移
          centre.x -= calLeft - left
          centre.y -= calTop - top
          //console.log('after zoom:', centre)
        }

        
        ctx.translate(centre.x, centre.y)
        self.drawRect = { x: dx, y: dy, width: dw, height: dh, canvasWidth: cw, canvasHeight: ch }
        // console.warn('will draw img:', iw, ih)
        // console.warn('will draw:', dx, dy, dw, dh, scale, eScale)
        
        if (self.isDrawEnhance) {
          const eiw = self.enhancedImage.width
          const eih = self.enhancedImage.height

          const pos = cw * self.splitPosition
          const ew = pos - dx - centre.x
          // console.log(`splitPosition: ${self.splitPosition}, draw width: ${pos}`)

          // console.warn(`draw image from: ${dx} to ${dx + ew}.`)
          const fromRect = { x: 0, y: 0, width: ew / scale, height: ih }
          const toRect = { x: dx, y: dy, width: ew, height: dh }
          drawImageWithRect(ctx, self.image, fromRect, toRect)

          // console.warn(`draw enhanced image from: ${dx + ew} to ${dw}.`)
          const enhancedFromRect = { x: ew / eScale, y: 0, width: eiw - ew / eScale, height: eih }
          const enhancedToRect = { x: dx + ew, y: dy, width: dw - ew, height: dh }
          drawImageWithRect(ctx, self.enhancedImage, enhancedFromRect, enhancedToRect)
          
          // console.warn('draw line rect at:', pos)
          self.splitLineRect = { x: pos, y: 0, width: defaultSplitLineWidth, height: ch }
          drawSplitLine(ctx, self.splitLineRect, 'rgba(255, 255, 255, .7)')
        } else {
          // console.warn('draw image:', dx, dy, dw, dh)
          ctx.drawImage(self.image, dx, dy, dw, dh)
        }

        ctx.restore()
        // console.groupEnd('enhance draw.')
      }
      if (!stopRendering) window.requestAnimationFrame(render)
    }


    function drawSplitLine (ctx, rect, lineColor) {
      // console.log('drawSplitLine:', rect, lineColor)
      // ctx.save();
      ctx.restore()
      // draw
      ctx.beginPath()
      ctx.fillStyle = lineColor
      ctx.fillRect(rect.x, rect.y, rect.width, rect.height)

      // ctx.restore();
    }

    function isMouseDownOnLine (evt) {
      if (self.splitLineRect) {
        const {x, y, width, height} = self.splitLineRect
        var rect = canvas.getBoundingClientRect(),
          pos = {
            x: evt.clientX - rect.left,
            y: evt.clientY - rect.top
          }
        
        // console.log('rect:', rect, pos)
        return x <= pos.x && pos.x <= x + width &&
               y <= pos.y && pos.y <= y + height
      }
      return false
    }

    function onMouseDown (evt) {
      if (evt.button === 0) { // left/main button
        leftMouseButtonDown = true
        if (isMouseDownOnLine(evt)) {
          // console.warn('MouseDownOnLine')
          self.isMoveLine = true
        }
      }
    }

    function onTouchStart (evt) {
      leftMouseButtonDown = true
      if (isMouseDownOnLine(evt)) {
        // console.warn('MouseDownOnLine')
        self.isMoveLine = true
      }
    }

    function onTounChend () {
      activeMoveElement = centre
      leftMouseButtonDown = false
      self.isMoveLine = false
    }

    function onMouseUp (evt) {
      if (evt.button === 0) { // left/main button
        activeMoveElement = centre
        leftMouseButtonDown = false
        self.isMoveLine = false
      }
    }

    // function onMouseWheel(evt){
    //   if (!evt) evt = event;
    //   evt.preventDefault();
    //   if(evt.detail < 0 || evt.wheelDelta > 0){ // up -> smaller
    //     self.zoomOut();
    //   } else { // down -> larger
    //     self.zoomIn();
    //   }
    // }

    function onMouseMove (evt) {
      var rect = canvas.getBoundingClientRect(),
        newPos = {
          x: evt.clientX - rect.left,
          y: evt.clientY - rect.top
        }
      // console.log('rect:', rect)
      // console.log('nx, ny:', newPos.x, newPos.y)

      mouseLastPos = mouseLastPos || { x: 0, y: 0 }
      var deltaX = newPos.x - mouseLastPos.x,
        deltaY = newPos.y - mouseLastPos.y
      
      //修改光标样式
      if(self.splitLineRect) {
        const {x, y, width, height} = self.splitLineRect
        if (self.isMoveLine || (x <= newPos.x && newPos.x <= x + width && y <= newPos.y && newPos.y <= y + height)) {
          canvas.style.cursor = 'ew-resize'
        } else {
          canvas.style.cursor = 'default'
        }
      }

      if (leftMouseButtonDown) {
        if (self.isMoveLine) {
          // 移动分割线
          const oldSplitPosition = self.splitPosition
          const currentPos = canvas.width * self.splitPosition
          let newPos = currentPos + deltaX

          const imgStartX = self.drawRect.x
          const imgEndX = self.drawRect.x + self.drawRect.width
          const startX = imgStartX > 0 ? imgStartX : 0
          const endX = imgEndX > canvas.width ? canvas.width : imgEndX
          
          if (newPos < startX) {
            newPos = startX
          } else if (newPos > endX - defaultSplitLineWidth) {
            newPos = endX - defaultSplitLineWidth
          }
          const newSplitPosition = newPos / canvas.width
          if (newSplitPosition !== oldSplitPosition) {
            self.splitPosition = newSplitPosition
            // console.warn('change split position to:', self.splitPosition)
            dirty = true
          }
        } else if (activeMoveElement === centre) {
          // 移动图片
          const newPosX = activeMoveElement.x + deltaX
          const newPosY = activeMoveElement.y + deltaY
          const { isNeedMove, moveX, moveY } = checkMove(newPosX, newPosY)
          if (isNeedMove) {
            // console.warn('change move position to:', moveX, moveY)
            activeMoveElement.x = moveX
            activeMoveElement.y = moveY
            dirty = true
          }
        } else {
          // console.error('other element move.')
        }
      } else {
        // console.error('not left mouse button down move.')
      }
      mouseLastPos = newPos
    }

    function addEventListener (eventTarget, eventType, listener) {
      eventTarget.addEventListener(eventType, listener)
      events.push({eventTarget: eventTarget, eventType: eventType, listener: listener})
    }

    function removeAllEventListeners () {
      var _i, _events = events.slice(), _current
      for (_i = 0; _i < _events.length; _i++) {
        _current = _events[_i]
        _current.eventTarget.removeEventListener(_current.eventType, _current.listener)
      }
      events = []
    }

    function addEventListeners () {
      // dragging image or ui-elements
      addEventListener(canvas, 'mousedown', onMouseDown)
      addEventListener(canvas, 'mouseup', onMouseUp)

      // addEventListener(canvas, 'touchstart ', onTouchStart)
      // addEventListener(canvas, 'touchmove', onMouseMove)
      // addEventListener(canvas, 'tounchend', onTounChend)
      // zooming
      // addEventListener(canvas, 'DOMMouseScroll', onMouseWheel);
      // addEventListener(canvas, 'mousewheel', onMouseWheel);

      // moving
      addEventListener(canvas, 'mousemove', onMouseMove)

      // setting answer
      // addEventListener(canvas, 'click', onMouseClick);
    }

    this.dispose = function () {
      removeAllEventListeners()
      stopRendering = true
    }

    this.refresh = function () {
      self.dirty = true
    }

    function initialize () {
      //// init image
      self.image.addEventListener('load', onImageLoad, false)
      self.image.src = imageUrl

      self.enhancedImage.addEventListener('load', onEnhancedImageLoad, false)
      
      //// init Input handling
      addEventListeners()
    }

    initialize()
  }

  window.ImageViewer = ImageViewer
}(window))
