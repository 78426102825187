<template>
  <modal class="loginregister-modal" name="ChangePassword" :width="modalWidth" :height="modalHeight">
    <el-container class="fullContent">
      <el-aside class="fullContent LoginRegisterAsidePhone">
        <div class="image_ai">
          <span>
            {{$t('Hello')}} ~
          </span>
          <p>
            {{$t('Login with your AVCLabs AI account Access free credits and save image without watermark.')}}
          </p>
        </div>
      </el-aside>
      <el-main class="fullContent">
        <div class="flexRowEnd img_del">
          <img :src="$Image('close.png')" class="thumbnail" @click="onClose" />
        </div>
        <!-- 修改密码 -->
        <div class="flexColCenter">
          <div class="register_account" style="padding-top: 38px;">
            <h3>{{$t('Change Password')}}</h3>
          </div>
          <div class="signUp-box flexColCenter">
            <div style="margin-top:32px; width:100%;" class="flexColCenter">
              <div class="loginInputBox inputChangePassword flexColStart">
                <el-input
                  style="padding-bottom:22px;"
                  :placeholder="$t('Please enter old password')"
                  size="small"
                  :type="isShowPassword ? 'text' : 'password'"
                  @blur="onOld"
                  maxlength="16" 
                  minlength="8"
                  v-model="oldPassword">
                </el-input>
                  <img class="hide3" v-if="!isShowPassword" @click="onShowPassword" :src="$Image('ico-hide.png')" alt="">
                  <img class="hide3" v-if="isShowPassword" @click="onShowPassword" :src="$Image('ico-display.png')" alt="">
                <span v-if="oldPrompt" class="errorText">{{$t('Old Password cannot be blank.')}}</span>
                <span v-if="oldpwdError" class="errorText">{{$t('Old password error.')}}</span>
              </div>
              <div class="loginInputBox inputChangePassword flexColStart">
                <el-input
                  style="padding-bottom:22px;"
                  :placeholder="$t('Please enter new password')"
                  size="small"
                  :type="isShowPassNeword ? 'text' : 'password'"
                  @blur="onNew"
                  maxlength="16"
                  minlength="8"
                  v-model="newPassword">
                </el-input>
                  <img class="hide3" v-if="!isShowPassNeword" @click="onShowNewPassword" :src="$Image('ico-hide.png')" alt="">
                  <img class="hide3" v-if="isShowPassNeword" @click="onShowNewPassword" :src="$Image('ico-display.png')" alt="">
                <span v-if="newPrompt" ref="newPromptSpan" class="errorText">{{$t('New Password cannot be blank.')}}</span>
              </div>
              <div class="loginInputBox inputChangePassword flexColStart">

                <el-input
                  :placeholder="$t('Please confirm new password')"
                  style="padding-bottom:22px;"
                  size="small"
                  :type="isShowPassNeword ? 'text' : 'password'"
                  @blur="onConfirm"
                  maxlength="16"
                  minlength="8"
                  v-model="confirmPassword">
                </el-input>
                  <img class="hide3" v-if="!isShowPassNeword" @click="onShowNewPassword" :src="$Image('ico-hide.png')" alt="">
                  <img class="hide3" v-if="isShowPassNeword" @click="onShowNewPassword" :src="$Image('ico-display.png')" alt="">
                <span v-if="confirmPrompt" ref="confirmSpan" class="errorText" style="bottom: -7px;">{{$t('Repeat Password cannot be blank.')}}</span>

              </div>

              <div class="errorStyle" v-if="loading">
                <i class="el-icon-loading"></i>
              </div>
              <el-button class="btn-process" @click="onChangePwd" style="margin-top:20px;">
                {{ $t('Change Password') }}
              </el-button>
            </div>
          </div>
        </div>
        <!-- 修改密码 end-->

      </el-main>
    </el-container>
  </modal>
</template>

<script>
import JSEncrypt from 'jsencrypt'
import { mapState } from 'vuex'
import { changePassword, getLoginStamp } from '@/assets/js/request/api'
export default {
  name: 'ChangePassword',
  data() {
    return {
      modalWidth: '50%',
      modalHeight: '520px',
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      oldPrompt: false,
      newPrompt: false,
      confirmPrompt: false,
      username: '',
      oldpwdError: false,
      loading: false,
      isShowPassword: false,
      isShowPassNeword: false
    }
  },
  methods: {
    onClose() {
      this.loading = false
      this.oldPassword = ''
      this.newPassword = ''
      this.confirmPassword = ''
      this.$modal.hide('ChangePassword')
    },
    onOld () {
      if (this.oldPassword === '') {
        this.oldPrompt = true
      } else {
        this.oldPrompt = false
      }
      this.oldpwdError = false
    },
    onNew () {
      if (this.newPassword === '') {
        this.newPrompt = true
      } else {
        if (this.newPassword.length >= 8 && this.newPassword.length <= 16) {
          this.newPrompt = false
        } else {
          this.newPrompt = true
          this.$refs.newPromptSpan.innerHTML = this.$t('Please enter a password of 8 to 16 characters.')
        }
      }
    },
    onConfirm () {
      if (this.confirmPassword === '') {
        this.confirmPrompt = true
      } else {
        this.confirmPrompt = false
      }
    },
    onShowNewPassword () {
      this.isShowPassNeword = !this.isShowPassNeword
    },
    onShowPassword () {
      this.isShowPassword = !this.isShowPassword
    },
    async onChangePwd () {
      if (this.oldPassword !== '' && this.newPassword !== '' && this.confirmPassword !== '' && this.newPassword === this.confirmPassword &&!this.newPrompt) {
        this.loading = true
        const stamp = await getLoginStamp()
        const encrypt = new JSEncrypt()
        encrypt.setPublicKey(`-----BEGIN PUBLIC KEY-----
  MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC51ywBVBF899Rt6brFw1x4jM8b
  /VzqEq+sqK2m1CYPZJEJuHvJWeNjksZLSf9olOU1cu48dIdVNHUGa0vVZfTggP0d
  GeCtKDbLiwzM0jEBy7kbPhDT1wCB/nZCY1hxWBhd7o3vnJX8pGsEvytaw+nSVkLm
  xHsnQpbgspUG8cMEmwIDAQAB
  -----END PUBLIC KEY-----`)
        const encryptedOld = encrypt.encrypt(stamp + this.oldPassword)
        const encryptedNew = encrypt.encrypt(stamp + this.newPassword)
        // const encryptedConfirm = encrypt.encrypt(stamp.data + this.confirmPassword)
        console.log(encryptedOld)
        console.log(encryptedNew)
        // console.log(encryptedConfirm)
        let parmas = {
          username: this.userName,
          password: encryptedOld,
          newpassword: encryptedNew
        }
        const result = await changePassword(parmas)
        console.log(result)
        switch (result.code) {
          case 0:
            this.loading = false
            this.$message({
              showClose: true,
              message: this.$t('The password has been changed successfully, please use the new password to log in next time.'),
              type: 'success'
            })
            this.$modal.hide('ChangePassword')
            break
          case -11:
            this.error = true
            this.errorType = this.$t('Username does not exist.')
            break
          case -12:
            this.oldpwdError = true
            break
          case -13:
            this.error = true
            this.errorType = this.$t('User is existed')
            break
          case -14:
            this.error = true
            this.errorType = this.$t('User not actived.')
            break
          case -15:
            this.error = true
            this.errorType = this.$t('UID is null')
            break
          case -16:
            this.error = true
            this.errorType = this.$t('Username is null.')
            break
          default:
            this.error = true
            this.errorType = this.$t('unexpected error.')
        }
        console.log(result)
      } else if (this.newPassword !== this.confirmPassword) {
        this.confirmPrompt = true
        this.$nextTick(() => {
          this.$refs.confirmSpan.innerHTML = this.$t('The new password entered twice must be the same.')
        })
      } else {
        console.log('请检查密码格式')
      }
    }
  },
  computed: {
    ...mapState([
      'userName'
    ])
  },
  created () {
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/module/module-login.scss';
</style>
