<template>
  <modal class="preview-modal" name="PreviewModal" @before-open="beforeOpen" :clickToClose="false" :width="modalWidth" :height="modalHeight">
    <el-container class="fullContent">
      <el-header class="model-header flexRowBetween">
        <div class="text">{{ $t('Preview') }}</div>
        <div class="del">
          <img :src="$Image('close.png')" class="thumbnail" @click="onClose" />
        </div>
      </el-header>
      <el-container class="preview_modal_phone">
        <el-main class="fullContent previewmodal_phone_main" v-loading="isPreviewImgLoading" element-loading-custom-class="preview-modal-loading">
          <div id="barparent" ref="barparent" style="position: relative; width: 100%; height: 100%">

            <CanvasPhoto ref="canvasPhoto" :src="previewUrl" :isFit="isScaleToFit" @imageLoaded="onImageLoaded" @enhanceImageLoaded="onEnhanceImageLoaded" />
            <div v-if="!isPreviewImgLoading" class="before">{{ $t('Before') }}</div>
            <div v-if="!isPreviewImgLoading" class="after">{{ $t('After') }}</div>
            <div class="view-container flexRowCenter">
              <div v-if="isScaleToFit" @click="toggleFitMode(false)">
                <img :src="$Image('fit.png')" alt="1:1" />
              </div>
              <div v-else @click="toggleFitMode(true)">
                <img :src="$Image('fit.png')" />
              </div>
              <div @click="enlargeImg" class="enlarge" :class="{'disabled': enlargeBtnDisabled}">
                <img :src="$Image('enlarge.png')" />
              </div>
              <div @click="zoomOutImg" class="smaller" :class="{'disabled': zoomOutBtnDisabled}">
                <img :src="$Image('smaller.png')" />
              </div>
                <a href="https://www.w3school.com.cn/i/w3school_logo_white.gif" download="aaa" id="download"></a>
            </div>
          </div>
        </el-main>

        <el-aside class="modal-aside flexColBetween">
          <div class="preview-info flexColBetween">

            <div class="info-image history_model_img">
              <HistoryCustomizeImg :imgUrl='currentImage && currentImage.image' :enhancerUrl='currentImage && currentImage.dstImage' :handlelefts="currentImage && currentImage.width/2" :parentClientWidths="currentImage && currentImage.width"  :newsdata='currentImage'></HistoryCustomizeImg>
            </div>
            <hr class="history_model_img">
            <div class="info-image">
              <img :src="currentImage && currentImage.dstImage" /> 
            </div>


            <div class="info-container">
              <p>
                {{ $t('File Name') }} :
                <span>
                  {{ currentImage && currentImage.name }}
                </span>
              </p>
              <p>
                {{ $t('Input') }} :
                <span> {{ currentImage && currentImage.width }}*{{ currentImage && currentImage.height }} px </span>
              </p>
              <p>
                {{ $t('Output') }} :
                <span> {{ currentImage && currentImage.dstWidth }}*{{ currentImage && currentImage.dstHeight }} px </span>
              </p>
              <p>
                {{ $t('Operation') }} :
                <span>
                  {{ currentImage && currentImage.label }}
                </span>
              </p>
            </div>

            <el-button class="btn-download" @click="onDownload">  
              {{ $t('Download Image') }}
            </el-button>
     
          </div>

        </el-aside>
      </el-container>
    </el-container>
  </modal>
</template>

<script>
import { mapState } from 'vuex'
import * as Mutations from '@/store/mutations.js'
import CanvasPhoto from './CansvasPhoto.vue'
import { downloadFile } from "@/assets/js/utils/download.js"
import HistoryCustomizeImg from './HistoryCustomizeImg.vue'
export default {
  name: 'PreviewModal',
  data() {
    return {
      isPreviewImgLoading: true,
      params: {},
      modalWidth: '94%',
      modalHeight: '87%',
      isScaleToFit: true,
      ScaleList: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.25, 1.5, 1.75, 2, 3, 4], // 缩放比例固定参数
      divWidth: 0,
      divheight: 0,
      scaleNum: 1, // 图片缩放比例
      enlargeBtnDisabled: false, // 放大按钮禁用状态
      zoomOutBtnDisabled: false, // 缩小按钮禁用状态,
      handleleft: 450/2,
      parentClientWidth: 450
    }
  },
  methods: {
    beforeOpen(event) {
      this.params = event.params
    },
    toggleFitMode(fit) {
      this.isScaleToFit = fit
      
      //切换fit状态后，等待更新完成改变按钮状态
      this.$nextTick(() => {
        //1:1模式将缩放比例重置
        if (!this.isScaleToFit) {
          this.scaleNum = 1
          this.imageViewer.setZoom(this.scaleNum)
        }

        this.zoomOutBtnDisabled = this.imageViewer.getImageScale() <= this.ScaleList[0]
        this.enlargeBtnDisabled = this.imageViewer.getImageScale() >= this.ScaleList[this.ScaleList.length - 1]
      })
    },
    // 放大
    enlargeImg() {
      this.zoomOutBtnDisabled = false

      let scale = this.imageViewer.getImageScale()
      //fit 模式下找到正确的缩放系数
      if (this.isScaleToFit) {
        for (let i = 0; i < this.ScaleList.length; i++) {
          if (this.ScaleList[i] > scale) {
            this.scaleNum = this.ScaleList[i]
            break
          }
        }
      } else {
        let index = this.ScaleList.indexOf(this.scaleNum)
        this.scaleNum = this.ScaleList[index + 1]
      }

      //console.log('in zoom out, ', this.isScaleToFit, this.scaleNum, scale)

      this.isScaleToFit = false
      this.$nextTick(() => {
        this.imageViewer.setZoom(this.scaleNum)

        this.zoomOutBtnDisabled = this.imageViewer.getImageScale() <= this.ScaleList[0]
        this.enlargeBtnDisabled = this.imageViewer.getImageScale() >= this.ScaleList[this.ScaleList.length - 1]
      })
    },
    // 缩小
    zoomOutImg() {
      this.enlargeBtnDisabled = false

      let scale = this.imageViewer.getImageScale()
      //fit 模式下找到正确的缩放系数
      if (this.isScaleToFit) {
        for (let i = this.ScaleList.length - 1; i >= 0; i--) {
          if (this.ScaleList[i] < scale) {
            this.scaleNum = this.ScaleList[i]
            break
          }
        }
      } else {
        let index = this.ScaleList.indexOf(this.scaleNum)
        this.scaleNum = this.ScaleList[index - 1]
      }

      //console.log('in zoom in, ', this.isScaleToFit, this.scaleNum, scale)

      this.isScaleToFit = false
      this.$nextTick(() => {
        this.imageViewer.setZoom(this.scaleNum)

        this.zoomOutBtnDisabled = this.imageViewer.getImageScale() <= this.ScaleList[0]
        this.enlargeBtnDisabled = this.imageViewer.getImageScale() >= this.ScaleList[this.ScaleList.length - 1]
      })
    },
    onImageLoaded(size) {
      console.log('onImageLoaded', size)

      if (this.currentImage) {
        this.currentImage.width = size.width
        this.currentImage.height = size.height
      }

      let divNew = document.querySelector('#barparent')
      this.divWidth = divNew.offsetWidth
      this.divheight = divNew.offsetHeight

      this.imageViewer = this.$refs.canvasPhoto

      // 相当于点击预览按钮
      this.imageViewer.setEnhancedImage(this.outputUrl)
      this.toggleFitMode(false)

      // 显示购买提示弹窗
      if (!this.showBuyTips) return

      const messageHTML = `
        <p class="message-title">${this.$t('Congratulations')}!</p>
        <p class="message-content">
          ${this.$t('You’ve just processed animage successfully. Your free credits only have {SubscriptCredit} left for this month,check our pricing plan for more credits.', {SubscriptCredit: this.credits.SubscriptCredit})}  <a href="https://www.avclabs.com/photo-enhancer-ai.html" target="_blank">${this.$t('Check Plans')}</a>
        </p>
        `

      this.$message({
        showClose: true,
        message: messageHTML,
        dangerouslyUseHTMLString: true,
        customClass: 'message-tip',
        iconClass: 'icon-message-tip',
        center: true
      })

      this.$store.commit(Mutations.setShowBuyTips, false)
    },
    onEnhanceImageLoaded () {
       this.isPreviewImgLoading = false
    },
    onClose() {
      this.$modal.hide('PreviewModal')
    },
    onDownload() {
      downloadFile(this.currentImage.dstImage, this.currentImage.name)
    }
  },
  components: { CanvasPhoto, HistoryCustomizeImg },
  computed: {
    ...mapState(['showBuyTips', 'credits']),
    currentImage() {
      return this.params.currentImage
    },
    previewUrl() {
      if (!this.currentImage) return
      return this.currentImage.image
    },
    outputUrl() {
      return this.currentImage.dstImage
    }
  },
  watch: {
    currentImage () {
      this.isPreviewImgLoading = true
      this.isScaleToFit = true
    }
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/module/module-preview-modal.scss';
</style>
