<template>
  <el-container class="aside-view fullContent">
    <el-main class="aside-main flexColStart">
      <div  class="flex-row-start services" style="width:150px;box-sizing:border-box;">
        <img @click="onUploadView" :src="$Image('ai_features.png')" alt="aside" style="padding-right: 13px;">
        <span class="services_s">{{$t('AI Features')}}</span>
        <!-- <el-menu
          default-active="1"
          class="el-menu-vertical-demo"
          active-text-color="#d80650"
          @open="handleOpen"
          @close="handleClose">
          <el-menu-item v-for="(ai, i) in aiFeaturesList" :key="i" @click="onRouter(ai.name)" :index="i">
            <span slot="title">{{ai.title}}</span>
          </el-menu-item>
        </el-menu> -->
      </div>
        <ul @click.stop class="asude_ul">
          <li :class="{active_li: currentIndex === i}" v-for="(ai, i) in aiFeaturesList" :key="i" @click="onRouter(ai.path, i)">
            <p>{{$t(ai.title)}}</p>
          </li>
        </ul>
      <!-- AI Remover -->
      <div class="flex-row-start services" style="width:150px;box-sizing:border-box;">
        <img :src="$Image('ai_remover.png')" alt="aside" style="padding-right: 13px;">
        <span class="services_s" style="color: #626262;">
          {{$t('AI Remover')}}
        </span>
      </div>
        <ul class="asude_ul">
          <li :class="{active_li: activeBrush}" @click="onBrush">
            {{$t('Object Remover')}}
          </li>
        </ul>

      <div v-if="!loginShow" class="flex-row-start services" style="width:150px;box-sizing:border-box;">
        <img :src="$Image('my_files.png')" alt="aside" style="padding-right: 13px;">
        <span class="services_s" style="color: #626262;">
          {{$t('My files')}}
          </span>
      </div>
        <ul v-if="!loginShow" class="asude_ul">
          <li :class="{active_li: Process}" @click="onProcess">
            {{$t('Image to Process')}}
            </li>
          <li :class="{active_li: Processed}" @click="onHistory">
            {{$t('Processed Image')}}
            </li>
        </ul>
      <div class="flex-row-start services" style="width:150px;box-sizing:border-box;">
        <img :src="$Image('credits.png')" alt="aside" style="padding-right: 13px;">
        <span class="services_s" style="color: #626262;">
          {{$t('Credits')}}
          </span>
      </div>
        <ul class="asude_ul">
          <li :class="{active_li: Prices}" @click="onPricing">
            {{$t('Price')}}
          </li>
          <li :class="{active_li: Credit}" @click="onCredits">
            {{$t('Get Free Credits')}}
          </li>
        </ul>


      
    </el-main>
  </el-container>
</template>
<script>
import bus from '@/assets/js/bus'
import * as Mutations from '@/store/mutations.js'
import { mapState } from 'vuex'
export default {
  name: 'Aside',
  data() {
    return {
      aiFeaturesList: [
        {title:'Upscale', path: '/upscale-image', active: 'upscaleImage'},
        // {title:'Sharpen', path: '/Sharpen'},
        {title:'Face Retouch', path: '/face-refinement', active: 'FaceEnhancer'},
        {title:'Denoise', path: '/image-denoise', active: 'denoiseImage'},
        {title:'RemoveBG', path: '/remove-background', active: 'backgroundRemover'},
        {title:'Colorize', path: '/colorize-image', active: 'colorizePhotos'},
        {title:'Calibrate', path: '/color-calibrate'}
      ],
      currentIndex: Number,
      Processed: false,
      Process: false,
      Prices: false,
      Credit: false,
      activeBrush: false
    }
  },
  computed: {
    ...mapState([
      'loginShow'
    ])
  },
  methods: {
    onRouter (r, i) {
      console.log('Router path:', r)
      this.currentIndex = i
      this.$store.commit(Mutations.setViewtate, false)
      this.$router.push({path: r})
      this.Process = false
      this.Processed = false
      this.Prices = false
      this.activeBrush = false
      this.Credit = false
      bus.$emit('drawersApp')
    },
    onUploadView () {
      this.currentIndex = false
      this.$router.push({path:'/'})
      bus.$emit('drawersApp')
    },
    onProcess () {
      this.Process = true
      this.Processed = false
      this.currentIndex = false
      this.Prices = false
      this.Credit = false
      this.activeBrush = false
      this.$router.push({path:'/'})
      let process = 'process'
      bus.$emit('tabName', process)
        bus.$emit('drawersApp')
    },
    onHistory () {
      this.Processed = true
      this.Prices = false
      this.Process = false
      this.currentIndex = false
      this.Credit = false
      this.activeBrush = false
      this.$router.push({path:'/HistoryListView'})
        bus.$emit('drawersApp')
    },
    onPricing () {
      this.Process = false
      this.Processed = false
      this.currentIndex = false
      this.Credit = false
      this.activeBrush = false
      this.Prices = true
      this.$router.push({path:'/upgrade'})
      // window.location.href = 'https://www.avc.ai/upgrade.html#!'
        bus.$emit('drawersApp')
    },
    onCredits () {
      this.Credit = true
      this.Process = false
      this.Processed = false
      this.currentIndex = false
      this.Prices = false
      this.activeBrush = false
      this.$router.push({path:'/get-free-credits'})
      // window.location.href = 'https://www.avc.ai/get-free-credits.html'
        bus.$emit('drawersApp')
    },
    onBrush () {
      this.activeBrush = true
      this.Credit = false
      this.Process = false
      this.Processed = false
      this.currentIndex = false
      this.Prices = false
      this.$router.push({path:'/remove-objects'})
      bus.$emit('initList')
      bus.$emit('drawersApp')
    },
    async avcLabsLocationActive () {
      let locationPathName = location.search
      if (locationPathName) {
        let avcLocationActive = locationPathName.split('%22')[2].split('&')[1]
        console.log('avcLocationActive', avcLocationActive)
        if (avcLocationActive === 'removeObjects') {
          this.onBrush()
        } else {
          this.aiFeaturesList.forEach((a, index) => {
            if (avcLocationActive === a.active) {
              this.currentIndex = index
            }
          })
        }
      }
    }
  },
  // 监听路由
  watch: {
    // '$route' (to, from) { 
    // }
  },
  mounted () {
    // avclabs跳转后选中对应页面
    this.avcLabsLocationActive()
    bus.$on('onProcessed', this.onHistory)
    // document.body.addEventListener('click', () => {
    //   this.currentIndex = false
    // }, false)
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/scss/module/aside.scss';
</style>
