<template>
<div class="brush_canvas_photo fullContent">

<div v-show="!hasImage" class="upload_bg">

  <div class="flexColCenter">

    <div class="flexRowCenter upload-view upload_bgc mobile_col">
      <div class="flexColStart">
        <div class="photo_title_u">
          {{$t('Removing unwanted objects from images with deep neural networks')}}
          </div>
        <div class="photo_title_g">
          {{$t('Automatically Remove Unwanted Objects from Images with AI Object Remover')}}
          </div>
        <div class="photo_title_t">
          {{$t('Automatically remove people, text or logos, wires, date stamps, and any other unwanted objects from photos with the use of deep learning inpainting algorithms.')}}
           </div>
      </div>
      <img :src="$Image('remove_objects_top.png')" alt="">
    </div>
    <div class="upload_bgc fullContent upload-view">
      <div class="flexColCenter photo_uploads " ref='select_frame'  ondragstart="return false">
        <p class="photo_upload_d">
          {{$t('Drag image here or click to upload.')}}
        </p>
        <div class="upload_drag flexRowCenter">
          <div class="config_upload flexRowStart">
            <div class="click_upload flexRowCenter" @click="onBrushChoose">
              <img :src="$Image('upload_image.png')" alt="">
              <span>
                {{ $t('Upload Image') }}
              </span>
            </div>
          </div>
          <input type="file"
            style="visibility: hidden; width: 0;"
            accept=".png, .jpg, .jpeg, .bmp, .jfif, .webp"
            @change="onGetBrushFile"
            ref="uploadinput"
            id="upload-input">
        </div>
        <p class="photo_upload_l" @click="onDownloadMore"> </p>
      </div>
    </div>



    <!-- video -->
    <div class="flexColCenter index_video">
      <p>
        {{$t('Watch 1-Minute Video to Know How It Works for You')}}
        </p>
      <div class="complete-img complete_img_two">
        <video loop width="900" height="505" playsinline>
          <source src="../../assets/images/ai-object-remover.mp4" data-wf-ignore="true">
        </video>
        <div class="feature_video_play" @click="handleOpenDialog">
          <img src="https://assets-global.website-files.com/6005fac27a49a9cd477afb63/6025ac3ba629110e604db669_Circled%20Play.svg" loading="lazy" width="100" height="100" alt="video play button">
        </div>
      </div>
      <el-dialog width="75%" center :visible.sync='outVisible'>
        <video autoplay loop width="90%" height="680" muted controls>
          <source src="../../assets/images/ai-object-remover.mp4" data-wf-ignore="true">
        </video>
      </el-dialog>
    </div>
    
    <div class="upload-view upload_bgc flexRowCenter automat_fully">
      <div class="flexColCenter automatically_a">
        <img :src="$Image('icon1.png')" alt="">
        <p class="auto_p">
          {{$t('Image Inpainting')}}
          </p>
        <p class="auto_pw">
          {{$t('The deep learning-based image inpainting method helps remove unwanted objects from images and reconstruct the masking regions.')}}
          </p>
      </div>
      <div class="flexColCenter automatically_a">
        <img :src="$Image('icon2.png')" alt="">
        <p class="auto_p">
          {{$t('Remove Objects')}}
          </p>
        <p class="auto_pw">
          {{$t('Automatically remove people, text or logos, wires, date stamps, and any other unwanted objects from images.')}}
          </p>
      </div>
      <div class="flexColCenter automatically_a">
        <img :src="$Image('icon3.png')" alt="">
        <p class="auto_p">
          {{$t('Object Detection')}}
          </p>
        <p class="auto_pw">
          {{$t('Object detection is applied and implemented by using machine and deep Learning techniques.')}}
          </p>
      </div>
      <div class="flexColCenter automatically_a">
        <img :src="$Image('icon4.png')" alt="">
        <p class="auto_p">
          {{$t('Face Retouching')}}
          </p>
        <p class="auto_pw">
          {{$t('Retouch portrait photos by removing wrinkles, acne, freckle, and any other unwanted blemishes.')}}
        </p>
      </div>
    </div>

    <!-- img1 -->
    <div class="flexColCenter upload-view form_image">
      <div class="form_image_u">
        {{ $t('Easily remove unwanted objects precisely in a few seconds, without being bulky and cumbersome') }}
      </div>
      <p class="form_image_a mar_tb">
        {{ $t('Most of you probably have some photos with some unwanted things on them, like tourists or unwanted person on the travle photos, wires on the landscape photos, sensitive logos, or private texts on some photos. Have you ever thought of removing or erasing the unwanted objects to get a clean, polished image in seconds?') }}
      </p>

      <p class="form_image_a" style="margin-bottom: 30px">
              {{ $t("We can't simply paint over the images because that will replace black with white and this is of no use. The advent of machine learning (a technique called image inpainting) has made it possible to remove unwanted objects and replace them with content that would seamlessly fit into an image.") }}
      </p>
      <CustomizeImg :imgUrl='noise1' :enhancerUrl='noise2' :handlelefts="handleleft" :parentClientWidths="parentClientWidth"></CustomizeImg>

      <div class="flexRowCenter mar_t">
        <div class="config_upload flexRowStart">
          <div class="click_upload click_btn_w flexRowCenter" @click="onBrushChoose">
            <img :src="$Image('upload_image.png')" alt="">
            <span>
              {{ $t('Upload Image') }}
            </span>
          </div>
        </div>

        <!-- <div class="google_play_btn flexRowStart" @click="onGooglePlay">
          <div class="click_google_play flexRowCenter">
            <div class="google_play_icon"></div>
            <span>
              {{ $t('Google play') }}
            </span>
          </div>
        </div>

        <div class="google_play_btn flexRowStart">
          <div class="click_google_play download_w flexRowCenter" @click="onDownload">
            <div class="download_icon"></div>
            <span>
              {{ $t('Download for Win&Mac') }}
            </span>
          </div>
        </div> -->


      </div>
    </div>
    <!-- img2 -->
    <div class="flexColCenter upload-view form_image">
      <div class="form_image_u">
        {{ $t('Remove unwanted people or tourists from the travel photo') }}
        
      </div>
      <p class="form_image_y">
        {{ $t("The people and tourists in your travel photos are a real distraction. They take away from the perfect shot you were trying to get, but don't worry--there's an easy way to remove them without having any negative impact on quality! AVC AI Object Remover’s deep-learning inpainting algorithm can auto detects what needs removing and help you get rid of any unwanted people or objects from the photos, so as to keep your photos free from the distractions of other people.") }}
      </p>

      <CustomizeImg :imgUrl='refinement1' :enhancerUrl='refinement2' :handlelefts="handleleft" :parentClientWidths="parentClientWidth"></CustomizeImg>

      <div class="flexRowCenter mar_t">
        <div class="config_upload flexRowStart">
          <div class="click_upload click_btn_w flexRowCenter" @click="onBrushChoose">
            <img :src="$Image('upload_image.png')" alt="">
            <span>
              {{ $t('Upload Image') }}
            </span>
          </div>
        </div>

        <!-- <div class="google_play_btn flexRowStart" @click="onGooglePlay">
          <div class="click_google_play flexRowCenter">
            <div class="google_play_icon"></div>
            <span>
              {{ $t('Google play') }}
            </span>
          </div>
        </div>

        <div class="google_play_btn flexRowStart">
          <div class="click_google_play download_w flexRowCenter" @click="onDownload">
            <div class="download_icon"></div>
            <span>
              {{ $t('Download for Win&Mac') }}
            </span>
          </div>
        </div> -->


      </div>
    </div>

    <!-- img3 -->
    <div class="flexColCenter upload-view form_image">
      <div class="form_image_u">
        {{ $t('Erase wires, wire poles, or any other unwanted chaos from the landscape photo') }}
        
      </div>
      <p class="form_image_y">
        {{ $t('When you photograph nature, you don’t want to see electric wires, telephone poles, irrigation pipes, or any other man-made intrusions on your natural scene. How do you get rid of them? Erasing man-made intrusions from the landscape photograph is actually easier than you might think. All it takes is an image object remover, or image inpainting. With merely three simple steps you can erase wires, wire poles, or any other unwanted chaos from your beautiful scenery photos as if they never were there.') }}
      </p>

      <CustomizeImg :imgUrl='calibrator1' :enhancerUrl='calibrator2' :handlelefts="handleleft" :parentClientWidths="parentClientWidth"></CustomizeImg>

      <div class="flexRowCenter mar_t">
        <div class="config_upload flexRowStart">
          <div class="click_upload click_btn_w flexRowCenter" @click="onBrushChoose">
            <img :src="$Image('upload_image.png')" alt="">
            <span>
              {{ $t('Upload Image') }}
            </span>
          </div>
        </div>

        <!-- <div class="google_play_btn flexRowStart" @click="onGooglePlay">
          <div class="click_google_play flexRowCenter">
            <div class="google_play_icon"></div>
            <span>
              {{ $t('Google play') }}
            </span>
          </div>
        </div>

        <div class="google_play_btn flexRowStart">
          <div class="click_google_play download_w flexRowCenter" @click="onDownload">
            <div class="download_icon"></div>
            <span>
              {{ $t('Download for Win&Mac') }}
            </span>
          </div>
        </div> -->


      </div>
    </div>

    <!-- img4 -->
    <div class="flexColCenter upload-view form_image last_mb">
      <div class="form_image_u">
        {{ $t('Remove date stamps, stickers, text, or logo from images') }}
        
      </div>
      <p class="form_image_y">
        {{ $t("Watermarks, text, captions, logos, date stamps, stickers, and other unwanted content can ruin the aesthetic of an otherwise perfect image. Now with the AI-based object removal tool, detecting and removing these unwanted elements would be much easier and more accurate than ever, especially if you don't want to damage the background in the process. You can get rid of watermarks, text, captions, logos, date stamps, stickers, and other unwanted content from images without impacting the background, automatically and smartly.") }}
      </p>

      <CustomizeImg :imgUrl='background1' :enhancerUrl='background2' :handlelefts="handleleft" :parentClientWidths="parentClientWidth"></CustomizeImg>

      <div class="flexRowCenter mar_t">
        <div class="config_upload flexRowStart">
          <div class="click_upload click_btn_w flexRowCenter" @click="onBrushChoose">
            <img :src="$Image('upload_image.png')" alt="">
            <span>
              {{ $t('Upload Image') }}
            </span>
          </div>
        </div>

        <!-- <div class="google_play_btn flexRowStart" @click="onGooglePlay">
          <div class="click_google_play flexRowCenter">
            <div class="google_play_icon"></div>
            <span>
              {{ $t('Google play') }}
            </span>
          </div>
        </div>

        <div class="google_play_btn flexRowStart">
          <div class="click_google_play download_w flexRowCenter" @click="onDownload">
            <div class="download_icon"></div>
            <span>
              {{ $t('Download for Win&Mac') }}
            </span>
          </div>
        </div> -->


      </div>
    </div>



  </div>
 
  <FooterView></FooterView>
</div>

<AIRemoverEdit v-if="hasImage" :uploadImg='uploadingList' @onUploadNewImages='onBrushChoose' :FileAi='fileAi' :limitWidthHeight='limitMegapixel'>
</AIRemoverEdit>

</div>

</template>

<!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NK52HVJ');</script>
<!-- End Google Tag Manager -->

<script>
import { mapState } from 'vuex'
import { getCosToken, userLogin } from '@/assets/js/request/api'
import FooterView from './Footer.vue'
import AIRemoverEdit from './AIRemoverEdit.vue'
import CustomizeImg from './CustomizeImg.vue'
import * as Mutations from '@/store/mutations.js'
import bus from '@/assets/js/bus'
// import COS from '../../../public/cdn/cos-js-sdk-v5.min.js'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { getCookie } from '@/assets/js/utils/cookie'
export default {
  name: 'Remover',
    metaInfo: {
    title: 'AI Object Remover for Image: Automatically Remove and Erase Unwanted Objects from Images',
    meta: [
      {
        name: 'description',
        content: 'Automatically remove people, text or logos, wires, date stamps, and any other unwanted objects or elements from images with the use of deep-learning inpainting algorithms.'
      },
      {
        name: 'keywords',
        content: 'object remover, remove objects from image, image inpainting'
      }
    ]
  },
  data() {
    return {
      successAddImage: [],
      accessKeyId: null,
      accessKeySecret: null,
      stsToken: null,
      expiration: null,
      AllServernum: null,
      uploadingList: [],
      limitMegapixel: [],
      outVisible: false,
      handleleft: 926/2,
      parentClientWidth: 926,
      noise1: 'remove_unwanted_objects.jpg',
      noise2: 'remove_unwanted_objects1.jpg',
      refinement1: 'remove_unwanted_people.jpg',
      refinement2: 'remove_unwanted_people1.jpg',
      calibrator1: 'erasing_wires_and_power_lines.jpg',
      calibrator2: 'erasing_wires_and_power_lines1.jpg',
      background1: 'remove_text.jpg',
      background2: 'remove_text1.jpg',
      uploadImgBlob: '',
      fileAi: null
    }
  },
  components: {
    FooterView,
    AIRemoverEdit,
    CustomizeImg
  },
  computed: {
    ...mapState([
      'userId',
      'imageCredit',
      'processImagesLength',
      'credits',
      'config',
      'cosToken'
    ]),
    hasImage() {
      return this.uploadingList.length
    }
  },
  methods: {
    onGooglePlay () {
      window.open('https://play.google.com/store/apps/details?id=com.avclabs.android')
    },
    onBrushChoose () {
      try {
        this.$refs.uploadinput.click()
      } catch (error) {
        console.log(error)
      }
    },
    async onGetBrushFile (e) {
      var imgFile = e.target.files[0]
      this.fileAi = imgFile
      console.log('onGetBrushFile')
      // this.hasImage = true
      // await this.limitsMegapixels(imgFile)
      // console.log('AllServernum:', imgFile)
      let imgBlob = URL.createObjectURL(imgFile)
      this.uploadingList = imgBlob
      bus.$emit('setCanvasSize', this.fileAi)
      this.$refs.uploadinput.value = ''
    },
    async FilesListUpload (FilesList) {
      console.log('FilesList:', FilesList)
      this.uploadingList = []
      let res = await this.UploadImage(FilesList)
      if (res) {
        let counter = 1
        res.forEach(async (f, index, arr) => {
          this.UploadCos(f).then(async () => {
            await this.setWidtHeight(f)
            console.log('successAddImage:', counter, arr.length, this.successAddImage)
            if(counter === arr.length){
              this.AddImage(this.successAddImage)
              this.successAddImage = []
            }
          }).catch(() => {
            return
          }).finally(() => {
            counter++
          })
        })
      }
    },
 
    CheckImgExists(imgurl) {  
      var ImgObj = new Image()
      ImgObj.src = imgurl
      //没有图片，则返回-1  
      if (ImgObj.fileSize > 0 || (ImgObj.width > 0 && ImgObj.height > 0)) {  
          return true;  
      } else {  
          return false;
      }  
    },
    // 图片限制
    async UploadFiles (files) {
      if (files.length < 1) {
        return false
      }
      let fileSize = files.filter(item => item.size / 1024 / 1024 > 10)
      let filesType = files.filter(item => ['image/jpg', 'image/png', 'image/bmp', 'image/jpeg', 'image/jfif', 'image/webp'].indexOf(item.type) !== -1)
      files.forEach((item) => {
        let reader = new FileReader()
        reader.readAsDataURL(item)
        reader.onload = (e) => {
          const replaceSrc = e.target.result
          let imageObj = new Image()
          imageObj.src = replaceSrc
          imageObj.onload = () => {
            if (imageObj.width === 0 && imageObj.height === 0) {
              // this.$emit('changeHandleLoadingShow', false)
            }
          }
        }
   
      })
      console.log('fileSize', fileSize)
      if (fileSize.length) {
        let msg = this.$t('Maximum photo size is 10MB.')
        this.$confirm(msg, {
          confirmButtonText: this.$t('Ok'),
          showCancelButton: false,
          customClass: 'confirm',
          showClose: false,
          iconClass: 'confirm-icon'
        }).then(async () => {
        }).catch(() => {})
        return false
      }
      if (!filesType.length) {
        let msg = this.$t('Please upload the correct picture format.')
        this.$confirm(msg, {
          confirmButtonText: this.$t('Ok'),
          showCancelButton: false,
          customClass: 'confirm',
          showClose: false,
          iconClass: 'confirm-icon'
        }).then(async () => {
        }).catch(() => {})
        return false
      }

      let promiseAll = []
      // 上传前发送图片宽高给列表组件
      files.forEach((item, index) => {
        promiseAll[index] = this.limitsMegapixels(item) 
      })
      await Promise.all(promiseAll)

      console.log('this.limitMegapixel', this.limitMegapixel)

      let megapixel = this.limitMegapixel.filter(m => m.width * m.height > 14000000)
      if (megapixel.length) {
        let msg = this.$t('Maximum photo 14 Megapixels.')
        this.$confirm(msg, {
          confirmButtonText: this.$t('Ok'),
          showCancelButton: false,
          customClass: 'confirm',
          showClose: false,
          iconClass: 'confirm-icon'
        }).then(async () => {
        }).catch(() => {})
        this.limitMegapixel = []
        return false
      } else {
        console.log('21412412412412412')
      }
      return true
    },
    ExpirationDate (expiration) {
      let date = new Date(expiration).getTime()
      return date
    },
    sleep (t) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve()
        }, t);
      })
    },
    setWidtHeight (file) {
      return new Promise((resolve) => {
        const vm = this
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (e) => {
          const replaceSrc = e.target.result
          let imageObj = new Image()
          imageObj.src = replaceSrc
          imageObj.onload = () => {
            vm.successAddImage.push({
              id: file.id,
              name: file.name,
              width: imageObj.width,
              height: imageObj.height,
              status: 'success'
            })
            resolve()
          }
        }
      })
    },
    limitsMegapixels (file) {
      this.limitMegapixel = []
      return new Promise((resolve) => {
        const vm = this
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (e) => {
          const replaceSrc = e.target.result
          let imageObj = new Image()
          imageObj.src = replaceSrc
          imageObj.onload = () => {
            imageObj.width = 1200
            imageObj.height = 1200
            vm.limitMegapixel.push({
              width: imageObj.width,
              height: imageObj.height
            })
            resolve()
          }
        }
      })
    },
 
    onFailure (e) {
      console.log('onFailure', e)
      console.log('this.uploadingList', this.uploadingList)
      this.UploadCos(e.file).then(async () => {
        await this.setWidtHeight(e.file)
        console.log('successAddImage:', this.successAddImage)
        this.AddImage(this.successAddImage)
        this.successAddImage = []
      }).catch(() => {
        return
      })
    },
    // <<<<<匿名登录逻辑>>>>>
    async Fingerprint () {
      const fpPromise = FingerprintJS.load()
      const fp = await fpPromise
      const result = await fp.get()
      const visitorId = result.visitorId
      this.visitorId = visitorId
      return visitorId
    },
    SetUid (s) {
      let str = s
      let arr = str.split('')
      arr.splice(8, 0, '-')
      arr.splice(13, 0, '-')
      arr.splice(18, 0, '-')
      arr.splice(23, 0, '-')
      str = arr.join('')
      let str1 = 'RMBG-'
      let str2 = str1 + str
      return str2
    },
    async getModelData (sid) {
      let credits = JSON.parse(sessionStorage.getItem('Credits'))
      console.log(credits)
      if (credits) {
         this.creditInfo(credits)
      }
      this.$store.commit(Mutations.setUserId, sid)
      // 获取COS凭证
      const result = await getCosToken({sid: sid}, this)
      console.log('GetSTS Success, STS: ', result)
      this.$store.commit(Mutations.setCosToken, result)
      // 获取modelList
      // const { data } = await getModelList({sid})
      // let modeData = data.slice(3, 8)
      // this.$store.commit(Mutations.setModelList, modeData)
      // console.log('GetModelList Success, modelList: ', modeData)
    },
    // 登录后储存credits
    creditInfo (data) {
      let { VideoCredit, ImageCredit, UploadCredit, SubscriptCredit } = data
      console.log(`video_credit: ${VideoCredit}, image_credit: ${ImageCredit}, upload_credit: ${UploadCredit}, SubscriptCredit: ${SubscriptCredit}`)
      const credits = {
        VideoCredit: VideoCredit,
        ImageCredit: ImageCredit,
        UploadCredit: UploadCredit,
        SubscriptCredit: SubscriptCredit
      }
      this.$store.commit(Mutations.setCredits, credits)
    },
    async initWebClose () {
      let FingerPrintId = await this.Fingerprint()
      let Fpid = this.SetUid(FingerPrintId)
      const info = await userLogin({ uid: Fpid })
      if (info.code === -2) {
        // this.$refs.loginShow.openLogin()
        this.$store.commit(Mutations.setUserAnonymous, false)
      }
    },
    async googleLocation () {
      let locationPathName = location.search
      let sp = locationPathName.substring(1)
      let b = sp.split('&')
      let obj = {}
      b.map((a) => {
        let q = a.split('=')
        if (q[1]) {
          obj[q[0]] = decodeURIComponent(q[1])
        }
      })
      return obj
    },
    initList () {
      // this.hasImage = false
      this.uploadingList = []
    },
    onDownloadMore () {
      const lang = getCookie('lang')
      switch (lang) {
        case 'en':
          window.open('https://www.avclabs.com/download.html')
          break
        case 'de':
          window.open('https://www.avclabs.de/download.html')
          break
        case 'fr':
          window.open('https://www.avclabs.com/download.html')
          break
        case 'es':
          window.open('https://www.avclabs.com/download.html')
          break
        case 'ja':
          window.open('https://www.avclabs.jp/download.html')
          break
        default:
          window.open('https://www.avclabs.com/download.html')
          break
      }
    },
    onDownload () {
      const lang = getCookie('lang')
      console.log('lang', lang)
      switch (lang) {
        case 'en':
          window.open('https://www.avclabs.com/download.html')
          break
        case 'de':
          window.open('https://www.avclabs.de/download.html')
          break
        case 'fr':
          window.open('https://www.avclabs.com/download.html')
          break
        case 'es':
          window.open('https://www.avclabs.com/download.html')
          break
        case 'ja':
          window.open('https://www.avclabs.jp/download.html')
          break
        default:
          window.open('https://www.avclabs.com/download.html')
          break
      }
    },
    handleOpenDialog () {
      this.outVisible = true
    }
 
  },
  mounted () {
    // bus.$on('BrushChoose', this.onBrushChoose)
    this.$refs.select_frame.ondragleave = (e) => {
        e.preventDefault()
    }
    this.$refs.select_frame.ondrop = (e) => {
        e.preventDefault()
        const data = e.dataTransfer.files
        if (data.length < 1) {
            return
        }
        this.onGetBrushFile(data)
    }
    this.$refs.select_frame.ondragenter = (e) => {
        e.preventDefault()
    }
    this.$refs.select_frame.ondragover = (e) => {
        e.preventDefault()
    }
    this.AllServernum = this.credits.upload_credit
    bus.$on('initWebClose', this.initWebClose)
    let sid = sessionStorage.getItem('Sid')
    if (sid) {
      this.getModelData(sid) 
    }
    bus.$on('initList', this.initList)
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/scss/module/upload-view.scss';
@import '@/assets/scss/module/brush-cansvas-photo.scss';
</style>