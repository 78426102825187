<template>
<div class="fullContent" style="position: relative;height:100%;">
  <div class="upscalling_main">

    <div class="upload-view upload_bgc flexColCenter">

      <div class="flexRowCenter mobile_col">
        <div class="flexColStart photo_title">
          <div class="photo_title_g" style="padding: 58px 0 23px;">
            {{ $t('AI Portrait Enhancer: Instantly Retouch Portrait to Make it Stuning') }}
           
          </div>
          <div class="photo_title_t">
            {{ $t('Use AI portrait enhancer to instantly and automatically improve portrait and selfies photos and enhance facial details, reduce imperfections and blemishes, in a single click to tweak your portrait.') }}
         
          </div>
        </div>
        <img :src="$Image('face_retouching_top_banner.png')" alt="">
      </div>
      
      <div class="flexColCenter photo_upload " ref='select_frame'  ondragstart="return false">
        <p class="photo_upload_d">
          {{ $t('Drag image here or click to upload.') }}
          </p>
        <div class="upload_drag flexRowCenter">
          <div class="config_upload flexRowStart">
            <div class="click_upload flexRowCenter" @click="onChoose">
              <img :src="$Image('upload_image.png')" alt="">
              <span>
                {{ $t('Upload Image') }}
              </span>
            </div>
          </div>
        </div>
        <p class="photo_upload_l" @click="onDownloadMore">
          {{ $t('Download App to unlock more features') }}  ></p>
      </div>

    </div>
  
    <!-- img1 -->
    <div class="flexColCenter upload-view form_image">
      <div class="form_image_u">
        {{ $t('AI Portrait Enhancemant and Retouching') }}
        
      </div>
            <p class="form_image_a mar_tb">
              {{ $t("As the images captured by modern cameras are inevitably degraded by noise, which leads to deteriorated visual image quality, a useful tool is required to reduce noise without losing image features like edges, corners, and other sharp structures.") }}
   
      </p>
            <p class="form_image_a">
              {{ $t('Automatically and intelligently detect and enhance facial details like hair, eye, lips, and skin, adjust the light and tone of the portrait photos to make them stunning and outstanding.') }}
   
      </p>
            <p class="form_image_a mar_tb">
              {{ $t('This image denoiser based on fast and flexible denoising convolutional neural network performs well in image denoising and real details recovery.') }}
    
      </p>

      <CustomizeImg :imgUrl='img1' :enhancerUrl='img2' :handlelefts="handleleft" :parentClientWidths="parentClientWidth"></CustomizeImg>

      <div class="flexRowCenter mar_t">
        <div class="config_upload flexRowStart" @click="onTryFree">
          <div class="click_upload click_btn_w flexRowCenter">
            <span>
              {{ $t('Try it for free') }}
            </span>
          </div>
        </div>
        <div class="google_play_btn flexRowStart">
          <div class="click_google_play download_w flexRowCenter" @click="onDownload">
            <div class="download_icon"></div>
            <span>
              {{ $t('Download for Win&Mac') }}
            </span>
          </div>
        </div>
      </div>




    </div>

    <!-- img2 -->
    <div class="flexColCenter upload-view form_image">
      <div class="form_image_u">
        {{ $t('Automatically Balance The Color And Light') }}
        
      </div>
      <p class="form_image_a mar_tb">
        {{ $t('If your portraits suffer from bad light that makes them overexposed, underexposed, now you can fix that with a single click.') }}
        
      </p>

      <CustomizeImg :imgUrl='img3' :enhancerUrl='img4' :handlelefts="handleleft" :parentClientWidths="parentClientWidth"></CustomizeImg>

      <div class="flexRowCenter mar_t">
        <div class="config_upload flexRowStart" @click="onTryFree">
          <div class="click_upload click_btn_w flexRowCenter">
            <span>
              {{ $t('Try it for free') }}
            </span>
          </div>
        </div>
        <div class="google_play_btn flexRowStart">
          <div class="click_google_play download_w flexRowCenter" @click="onDownload">
            <div class="download_icon"></div>
            <span>
              {{ $t('Download for Win&Mac') }}
            </span>
          </div>
        </div>
      </div>

    </div>


    <!-- img3 -->
    <div class="flexColCenter upload-view form_image">
      <div class="form_image_u">
        {{ $t('Remove Unwanted Imperfections On The Face') }}
        
      </div>
      <p class="form_image_a mar_tb" style="text-align: center;">
        {{ $t('Removing small skin imperfections such as acne, wrinkles, scars, or even eye bags can be pretty easy and quick.') }}
        
      </p>

      <CustomizeImg :imgUrl='img5' :enhancerUrl='img6' :handlelefts="handleleft" :parentClientWidths="parentClientWidth"></CustomizeImg>

      <div class="flexRowCenter mar_t">
        <div class="config_upload flexRowStart" @click="onTryFree">
          <div class="click_upload click_btn_w flexRowCenter">
            <span>
              {{ $t('Try it for free') }}
            </span>
          </div>
        </div>
        <div class="google_play_btn flexRowStart">
          <div class="click_google_play download_w flexRowCenter" @click="onDownload">
            <div class="download_icon"></div>
            <span>
              {{ $t('Download for Win&Mac') }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- img4 -->
    <div class="flexColCenter upload-view form_image last_mb">
      <div class="form_image_u">
        {{ $t('Auto-Enhance Facial Detail And Depth') }}
        
      </div>
      <p class="form_image_a mar_tb">
        {{ $t('With the AI portrait algorithms to enhance the facial details, such as hair, eye, lips, and skin.') }}
      </p>

      <CustomizeImg :imgUrl='img7' :enhancerUrl='img8' :handlelefts="handleleft" :parentClientWidths="parentClientWidth"></CustomizeImg>

      <div class="flexRowCenter mar_t">
        <div class="config_upload flexRowStart" @click="onTryFree">
          <div class="click_upload click_btn_w flexRowCenter">
            <span>
              {{ $t('Try it for free') }}
            </span>
          </div>
        </div>
        <div class="google_play_btn flexRowStart">
          <div class="click_google_play download_w flexRowCenter" @click="onDownload">
            <div class="download_icon"></div>
            <span>
              {{ $t('Download for Win&Mac') }}
            </span>
          </div>
        </div>
      </div>


    </div>
    <FooterView></FooterView>
  </div>
</div>

</template>


<!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NK52HVJ');</script>
<!-- End Google Tag Manager -->

<script>
import CustomizeImg from '../common/CustomizeImg.vue'
import FooterView from '../common/Footer.vue'
import bus from '@/assets/js/bus'
import { getCookie } from '@/assets/js/utils/cookie'
export default {
  name: 'FaceRetouching',
  metaInfo: {
    title: 'AI Face Recovery and Refinement Tool to Retouch and Enhance Portrait Photos Automatically',
    meta: [
      {
        name: 'description',
        content: 'AI portrait retouching tool for you to beautify portrait photos instantly like smoothing skin, removing freckles, wrinkles, or other blemishes, especially recovering and enhancing facial details from blurry and out-of-focus photos.'
      },
      {
        name: 'keywords',
        content: 'ai face detect, face retouching online, face refinement, ai face recovery, enhance portraits online'
      }
    ]
  },
  data() {
    return {
      handleleft: 926/2,
      parentClientWidth: 926,
      img1: 'face_retouching_enhancer1.jpg',
      img2: 'face_retouching_enhancer2.jpg',
      img3: 'face_retouching_balance1.jpg',
      img4: 'face_retouching_balance2.jpg',
      img5: 'face_retouching_remove_imperfections1.jpg',
      img6: 'face_retouching_remove_imperfections2.jpg',
      img7: 'face_retouching_detail1.jpg',
      img8: 'face_retouching_detail2.jpg'
    }
  },
  components: {
    CustomizeImg,
    FooterView
  },
  methods: {
    onDownloadMore () {
      const lang = getCookie('lang')
      switch (lang) {
        case 'en':
          window.open('https://www.avclabs.com/download.html')
          break
        case 'de':
          window.open('https://www.avclabs.de/download.html')
          break
        case 'fr':
          window.open('https://www.avclabs.com/download.html')
          break
        case 'es':
          window.open('https://www.avclabs.com/download.html')
          break
        case 'ja':
          window.open('https://www.avclabs.jp/download.html')
          break
        default:
          window.open('https://www.avclabs.com/download.html')
          break
      }
    },
    onTryFree () {
      this.$router.push({path:'/'})
    },
    onChoose () {
      bus.$emit('uploadImage')
      bus.$emit('emitSelect', 'FaceRetouching')
      this.$router.push({path:'/'})
    },
    onDownload () {
      window.location.href = 'https://www.avclabs.com/download.html'
    }
  },

  mounted () {
    this.$refs.select_frame.ondragleave = (e) => {
        e.preventDefault()
    }
    this.$refs.select_frame.ondrop = (e) => {
        e.preventDefault()
        const data = e.dataTransfer.files
        if (data.length < 1) {
            return
        }
        // console.log('dataaaaaaa', data)
        bus.$emit('onGetFile', data)
        this.$router.push({path:'/'})
    }
    this.$refs.select_frame.ondragenter = (e) => {
        e.preventDefault()
    }
    this.$refs.select_frame.ondragover = (e) => {
        e.preventDefault()
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/scss/module/upload-view.scss';
</style>
