<template>
<div id="canvasContainer" class="flexColCenter fullContent">
  <canvas id="viewerCanvas" :width="canvasWidth" :height="canvasHeight"></canvas>
</div>
  
</template>

<script>
  require('@/assets/js/viewer.js')
  const ScaleType = window.ScaleType
  const ImageViewer = window.ImageViewer

  const { debounce, throttle } = require('lodash')
  console.log('throttle:', !!debounce, !!throttle)

  export default {
    name: 'canvas-photo',
    props: ['src', 'isFit'],
    data () {
      return {
        debounceResizeDraw: null,
        imageViewer: null,
        canvasWidth: 0,
        canvasHeight: 0
      }
    },
    watch: {
      isFit (nv) {
        if (this.imageViewer) {
          this.imageViewer.forceRender({
            scaleType: nv ? ScaleType.Fit : ScaleType.ActualSize
          })
        }
      },
      src (nv) {
        if (this.imageViewer) {
          this.imageViewer.setImage(nv)
        }
      }
    },
    methods: {
      getImageScale () {
        return this.imageViewer.getImageScale()
      },
      setZoom (zoom) {
        this.imageViewer.setZoom(zoom)
      },
      setEnhancedImage (eimg) {
        this.imageViewer.setEnhancedImage(eimg)
      },
      setCanvasSize () {
        const canvasContainer = document.querySelector('#canvasContainer')

        if (canvasContainer) {
          this.canvasWidth = canvasContainer.clientWidth - 60
          this.canvasHeight = canvasContainer.clientHeight - 60
          console.warn(`setCanvasSize to: ${this.canvasWidth} x ${this.canvasHeight}`)
        }

        if (this.imageViewer) {
          console.warn('forceRender')
          this.imageViewer.forceRender({ isCanvasSizeChange: true })
        }
      }
    },
    mounted () {
      this.throttleResizeDraw = throttle(() => {
        console.log('resize.')
        this.setCanvasSize()
      }, 50)
      
      window.addEventListener('resize', this.throttleResizeDraw)
      this.$nextTick(() => {
        this.setCanvasSize()
        this.imageViewer = new ImageViewer('viewerCanvas', this.src, (size) => {
          this.$emit('imageLoaded', size)
        }, () => {
          this.$emit('enhanceImageLoaded')
        })
        console.log('myImageViewer:', this.imageViewer)
      })
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.debounceResizeDraw)
    }
  }
</script>

<style lang="scss">
.fullContent {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}
.flexColCenter {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

// #canvasContainer { 
//   background-color: #323641;
//   border: solid 1px #464a57;
// }


</style>
