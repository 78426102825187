import { get, post } from './http'
import { objectToArray } from '../utils/array'

const addQueryString = (url, params) => {
    params.forEach(item => {
        const alreadyHasQueryString = url.indexOf('?')>-1
        const contactChar = alreadyHasQueryString ? '&' : '?'
        url += contactChar  + item.name + '=' + encodeURIComponent(item.value)
    })
    return url
}

const addJsonString = (url, params) => {
    const alreadyHasQueryString = url.indexOf('?')>-1
    const contactChar = alreadyHasQueryString ? '&' : '?'
    url += contactChar  + 'params=' + encodeURIComponent(JSON.stringify(params))
    return url
}

// 谷歌 登录
export const googleLogin = async p => {
    let url = 'TokenLogin'
    const queryStringArray = objectToArray(p)
    url = addQueryString(url, queryStringArray)
    return await get(url)
}

// 谷歌
// export const googleLogin = async p => {
//     let url = 'Auth/Google'
//     const queryStringArray = objectToArray(p)
//     url = addQueryString(url, queryStringArray)
//     return await get(url)
// }

// 删除用户自定义模块
export const removeWorkflow = async p => {
    // const { queryString, params } = p
    const queryStringArray = objectToArray(p)
    let url = 'RemoveWorkflow'

    url = addQueryString(url, queryStringArray)
    // url = addJsonString(url, params)
    return await post(url)
}

// 添加用户自定义模块
export const addWorkflow = async p => {
    const { queryString, params } = p
    const queryStringArray = objectToArray(queryString)
    let url = 'AddWorkflow'

    url = addQueryString(url, queryStringArray)
    url = addJsonString(url, params)
    return await post(url)
}
// 获取当前用户设置
export const getWorkspace = async p => {
    let url = 'GetWorkspace'
    const queryStringArray = objectToArray(p)
    url = addQueryString(url, queryStringArray)
    return await get(url)
}
// 保存当前用户设置
export const saveWorkspace = async p => {
    const { queryString, params } = p
    const queryStringArray = objectToArray(queryString)
    let url = 'SaveWorkspace'

    url = addQueryString(url, queryStringArray)
    url = addJsonString(url, params)
    return await post(url)
}
// 退出登录Logout
export const Logout = async p => {
    let url = 'Logout'
    const queryStringArray = objectToArray(p)
    url = addQueryString(url, queryStringArray)
    return await get(url)
}
// 获取时间戳
export const getLoginStamp = async p => {
    let url = 'GetLoginStamp'
    const queryStringArray = objectToArray(p)
    url = addQueryString(url, queryStringArray)
    return await get(url)
}
// 重置密码
export const ResetPassword = async p => {
    let url = 'ResetPassword'
    return await post(url, p)
}
// 修改密码
export const changePassword = async p => {
    let url = 'ChangePassword'
    return await post(url, p)
}
// 注册发送激活邮箱
export const sendEmailReactive = async p => {
    let url = 'Reactive'
    return await post(url, p)
}
// 用户登录
export const Login = async p => {
    let url = 'Login'
    return await post(url, p)
}
// 用户注册
export const userRegister = async p => {
    let url = 'Register'
    return await post(url, p)
}
// 匿名登录
export const userLogin = async p => {
    let url = 'AnonymousLogin'
    const queryStringArray = objectToArray(p)
    url = addQueryString(url, queryStringArray)
    return await get(url)
}
// 获取COS上传凭证
export const getCosToken = async (p, vueApp) => {
    let url = vueApp.STS_URL
    const queryStringArray = objectToArray(p)

    url = addQueryString(url, queryStringArray)
    return await get(url)
}
// 通知服务器开始上传图片到OSS
export const uploadImage = async p => {
    const { queryString, params } = p
    const queryStringArray = objectToArray(queryString)
    let url = 'UploadImages'
    url = addQueryString(url, queryStringArray)
    url = addJsonString(url, params)
    return await post(url)
}
// 通知服务器上传图片完成
export const addImage = async p => {
    const { queryString, params } = p
    const queryStringArray = objectToArray(queryString)
    let url = 'AddImages'
    url = addQueryString(url, queryStringArray)
    url = addJsonString(url, params)
    return await post(url)
}
// 获取图片列表
export const getImages = async p => {
    let url = 'GetImages'
    const queryStringArray = objectToArray(p)

    url = addQueryString(url, queryStringArray)
    return await get(url)
}
// 获取转换记录列表
export const getProcessHistory = async p => {
    const { queryString, params } = p
    let url = 'GetProcessHistory'
    const queryStringArray = objectToArray(queryString)

    url = addQueryString(url, queryStringArray)
    url = addJsonString(url, params)
    return await get(url)
}
// 删除服务器图片
export const deleteImages = async p => {
    const { queryString, params } = p
    const queryStringArray = objectToArray(queryString)
    let url = 'DeleteImages'

    url = addQueryString(url, queryStringArray)
    url = addJsonString(url, params)
    return await post(url)
}
// 删除服务器历史记录图片
export const deleteHistoryImages = async p => {
    const { queryString, params } = p
    const queryStringArray = objectToArray(queryString)
    let url = 'DeleteProcessHistory'

    url = addQueryString(url, queryStringArray)
    url = addJsonString(url, params)
    return await post(url)
}
// 获取当前模型配置
export const getModelList = async p => {
    let url = 'GetModelList'
    const queryStringArray = objectToArray(p)

    url = addQueryString(url, queryStringArray)
    return await get(url)
}
// 使用AI增强图片
export const getReqCredits = async p => {
    const { queryString, params } = p
    const queryStringArray = objectToArray(queryString)
    let url = 'GetReqCredits'

    url = addQueryString(url, queryStringArray)
    url = addJsonString(url, params)
    return await post(url)
}
// 使用AI增强图片
export const enhanceImage = async p => {
    const { queryString, params } = p
    const queryStringArray = objectToArray(queryString)
    let url = 'ProcessImages'

    url = addQueryString(url, queryStringArray)
    url = addJsonString(url, params)
    return await post(url)
}
// 获取当前处理进度
export const getProcessStatus = async p => {
    let url = 'GetImageProcessStatus'
    const queryStringArray = objectToArray(p)

    url = addQueryString(url, queryStringArray)
    return await get(url)
}
