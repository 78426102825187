/**
 * 获取数组中随机的一项
 * @param {Object[]} arr
 * @returns {*}
 */
export const getRandomItem = arr => arr[Math.floor(Math.random() * arr.length)]

/**
 * 将对象转换成数组
 * 对象中每个成员对应数组中的一项
 * 每个成员的键名对应数组中该项的name
 * 每个成员的键值对应数组中该项的value
 * @param {Object} obj
 * @returns {Array}
 */
export const objectToArray = obj => {
    let arr = []
    for (let key in obj) {
        let newObj = {}
        newObj.name = key
        newObj.value = obj[key]
        arr.push(newObj)
    }
    return arr
}
