<template>
  <div style="height: 100%; overflow: hidden;" class="model-param flexColBetween">
    <ModelSetting></ModelSetting>
    <el-button class="btn-process" :disabled="btnDisabled || isEnhancing || setCustomizeState" @click="onEnhance">
      {{ $t('Start to Process') }}
    </el-button>
    <p class="process_note">
      {{ $t('Note: The final price in credits varies depending on the models applied.') }}
    </p>
  </div>
</template>

<script>
import ModelSetting from './ModelSetting.vue'
import { mapState } from 'vuex'
import bus from '@/assets/js/bus'
export default {
  name: 'ModelParam',
  data() {
    return {
      Timer: null,
      AnonymousState: false
    }
  },
  methods: {
    async onEnhance () {
      console.log('AnonymousLoginAnonymousLogin', this.AnonymousLogin)
      if (this.AnonymousLogin === true) {
        this.AnonymousState = true
        bus.$emit('openLogin')
      } else {
        this.$emit('onEnhance')
      }
    },
    onClose () {
      if (this.AnonymousState === true && this.AnonymousLogin === true) {
        this.AnonymousState = false
        this.$emit('onEnhance')
      }
    }
  },
  props: ['btnDisabled'],
  components: { ModelSetting },
  mounted () {
    bus.$on('onCloseLogin', this.onClose)
  },
  computed: {
    ...mapState(['AnonymousLogin', 'modelList', 'setCustomizeState', 'isEnhancing'])
  }
}
</script>

<style scoped lang="scss">
  @import "@/assets/scss/module/module-model-params.scss";
</style>
