/**
 * Set Cookie
 * @param {String} key
 * @param {String} value
 * @param {Number} days
 * @param {String} path
 */
 export const setCookie = (key, value, days, path = '/') => {
    let date = new Date()
    date.setDate(date.getDate() + days)
    date.setHours(date.getHours())
    document.cookie = encodeURIComponent(key) + '=' + encodeURIComponent(value) + ';expires=' + date.toUTCString() + ';path=' + path
}

/**
 * Get Cookie Value by Key
 * @param {String} key
 * @returns {String}
 */
export const getCookie = key => {
    let cookieStr = document.cookie

    if (cookieStr) {
        let cookieList = cookieStr.split('; ')

        for (let item of cookieList) {
            let cookieKey = decodeURIComponent(item.split('=')[0])
            let cookieValue = decodeURIComponent(item.split('=')[1])

            if (cookieKey === key) {
                return cookieValue
            }
        }

        return ''
    } else {
        return ''
    }
}
