<template>

  <div class="fullContent  PhotoList_Phone">
    <div class="photo-list flexColBetween">
      <div class="config-container flexRowBetween">
        <div class="flexRowStart">
          <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange" class="select-all">
            {{ $t('Select All') }}
          </el-checkbox>
          <div class="delete-container flexRowCenter mobile_delete" :class="checkedPhotoList.length === 0 ? 'disabled' : ''" @click="onBatchDelete">
            <img :src="$Image('del6.png')" alt="delete" class="icon-delete" />
            <span>{{ $t('Delete') }}</span>
          </div>
        </div>
        <div class="upload normal_btn mobile_delete"  @click="uploadImage">
          {{ $t('Upload New Images') }}
        </div>

        <div class="flexRowStart mobile_photoList">
          <div class="delete-container flexRowCenter" :class="checkedPhotoList.length === 0 ? 'disabled' : ''" @click="onBatchDelete">
            <img :src="$Image('del6.png')" alt="delete" class="icon-delete" />
            <span>{{ $t('Delete') }}</span>
          </div>
          <div class="normal_btn mobile_btn_upload flexRowCenter"  @click="uploadImage">
            <img :src="$Image('btn_upload_new_images.png')" alt=""> 
          </div>
        </div>

        
   

      </div>

      <el-checkbox-group v-model="checkedPhotoList" class="fullContent group group_phone">
        <div class="photo-item flexRowBetween photo_item_Phone" :class="item.status === 'failure' ? 'disabled' : ''" v-for="item in currentPhotoList" :key="item.rid" @click="onCheckedPhoto(item)">
          <div class="left flexRowStart">
            <el-checkbox :label="item" :disabled="['failure'].includes(item.status)" @click.native.prevent><br /></el-checkbox>
            <img :src="item.thumb" class="thumbnail" @error="errorImg" />
            <div class="info-container flex-col-center-lefts">
              <div class="name">{{ item.name }}</div>
              <div class="size">{{ $t('Input') }}: {{ item.width }} x {{ item.height }} px</div>
            </div>
          </div>
          <div class="right flexRowBetween">
            <div class="status flexRowStart" v-if="item.status === 'upload'">
              <svg viewBox="25 25 50 50" class="loading">
                <circle cx="50" cy="50" r="20" fill="none"></circle>
              </svg>
              {{ $t('Uploading...') }}
            </div>

            <div class="status flexRowStart" v-else-if="['ready', 'completed', 'active', 'waiting'].includes(item.status)">
              <img :src="$Image('9_ready.png')" class="status-img" alt="ready" />
              {{ $t('Ready') }}
            </div>

            <div class="status flexRowStart" v-else-if="item.status === 'failure'">
              <img :src="$Image('15_failed.png')" class="status-img" alt="failed" />
              {{ $t('Failed') }}
            </div>
 
            <!-- <el-button v-if="item.status === 'failed'"  @click="onFailure(item)"> 
               <i class="el-icon-refresh-right"></i>
            </el-button> -->
            <el-button class="delete" :disabled="['upload', 'progress'].includes(item.status)" @click.stop="onDelete(item)">
              <img :src="$Image('del.png')" class="thumbnail" />
            </el-button>
          </div>
        </div>
      </el-checkbox-group>
 
    </div>
    <div class="home-aside">
      <ModelParamView @onEnhance="onEnhance" :btnDisabled="checkedPhotoList.length === 0"></ModelParamView>
    </div>

  <!-- <FooterView></FooterView> -->
  </div>

</template>

<script>
import { mapState } from 'vuex'
// import FooterView from './Footer.vue'
import bus from '@/assets/js/bus'
import ModelParamView from './ModelParam.vue'

export default {
  name: 'PhotoList',
  data() {
    return {
      checkedPhotoList: [],
      defaultDeleteMsg: this.$t('Delete this image?'),
      isIndeterminate: true,
      checkAll: false,
      customColor: 'red'
    }
  },
  methods: {
    onEnhance() {
      this.$emit('onEnhance')
    },
    errorImg (event) {
      let img = event.srcElement
      img.src = require('@/assets/images/image_loading.png')
      img.onerror = null
    },
    handleCheckAllChange(val) {
      if (val) {
        this.currentPhotoList.forEach(item => {
          this.onCheckedPhoto(item, true)
        })
      } else {
        this.checkedPhotoList = []
      }
      
      this.isIndeterminate = false
    },
    onCheckedPhoto(item, isCheckAll = false) {
      if (['failure'].includes(item.status)) return
      if (this.checkedPhotoList.includes(item)) {
        if (isCheckAll) return
        this.checkedPhotoList.splice(this.checkedPhotoList.indexOf(item), 1)
      } else {
        this.checkedPhotoList.push(item)
      }
    },
    onDelete(image) {
      this.$emit('deleteImages', [image], this.$t('Delete this image?'))
    },
    onFailure (image) {
      bus.$emit('onFailure', image)
      console.log('imageimageimageimageimageimage', image)
    },
    onBatchDelete() {
      console.log('process onBatchDelete')
      const msg = this.$t('Delete selected images?')
      const isAll = this.checkedPhotoList.length === this.currentPhotoList.length
      this.$emit('deleteImages', this.checkedPhotoList, msg, isAll)
    },
    uploadImage() {
      console.log('uploadImage')
      bus.$emit('uploadImage')
    },
    modifyCheckedPhotoList(checkedPhotoList) {
      this.checkedPhotoList = checkedPhotoList
    },
    modifyIsIndeterminate(isIndeterminate) {
      this.isIndeterminate = isIndeterminate
    }
  },
  props: ['currentPhotoList', 'btnDisabled'],
  components: {
    ModelParamView
  },
  mounted() {
    this.$emit('checkedPhotoChanged', this.checkedPhotoList)
  },
  computed: {
    ...mapState(['userId', 'setCustomizeState']),
    accomplishNum () {
      return this.currentPhotoList.filter(item => item.status === 'accomplish').length
    }
  },
  watch: {
    checkedPhotoList(nv) {
      this.$emit('checkedPhotoChanged', nv)
      let checkedCount = nv.length
      if (checkedCount === 0 && this.currentPhotoList.length === 0) {
        this.isIndeterminate = false
        return
      }
      this.checkAll = checkedCount === this.currentPhotoList.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.currentPhotoList.length
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/module/module-photo-list.scss';
</style>
