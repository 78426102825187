<template>
<div class="fullContent" style="position: relative;height:100%;">
  <div class="credits_main">

    <div class="page-buy-title-area bg-11">
			<div class="container">
				<div class="page-buy-title-content mt-50">
					<h2>{{$t('Earn Free Credits: Follow Us Or Review Us To Get Rewarded!')}}</h2>
					<p class="text-white">{{$t("We'll give you 60 free credits once you followed/subscribed to us, or your review is published.")}}</p>
				</div>
			</div>
		</div>

    <div class="flexColCenter">

      <el-tabs v-model="activeName">
        <el-tab-pane :label="$t('Follow Us')" name="first">
          <div class="flexRowCenter follow_us">

            <div class="flexColCenter single-blog">
              <img :src="$Image('follow-us-on-facebook.png')" alt="">
              <span>{{$t('Follow on Facebook')}}</span>
              <div class="blog-content">
								<h3>
									<a href="https://www.facebook.com/AVCLabs.Official2022" target="_blank">{{$t('Follow Us on Facebook')}}</a>
								</h3>
								<p class="text-white">{{$t("Follow us on Facebook and get 60 free credits. Please send us your Facebook profile link and registration email (you signed up for our service) to support@avclabs.com and we'll add the credits to your account.")}}</p>
								<a href="https://www.facebook.com/AVCLabs.Official2022" target="_blank" class="read-more-for-credits">
                {{$t('Follow Us to Get 60 Credits')}}
									
								</a>
							</div>
            </div>

            <div class="flexColCenter single-blog">
              <img :src="$Image('follow-us-on-twitter.png')" alt="follow us on twitter">
              <span>{{$t('Follow on Twitter')}}</span>
              <div class="blog-content">
                <h3> <a href="https://twitter.com/AVCLabsofficial" target="_blank"> {{$t('Follow Us on Twitter')}} </a> </h3>
                <p class="text-white">{{$t("Follow us on Twitter and get 60 free credits. Please send us your Twitter profile link and registration email (you signed up for our service) to support@avclabs.com and we'll add the credits to your account.")}}</p>
                <div @click="onFollow" class="read-more-for-credits">
                  {{$t('Follow Us to Get 60 Credits')}}
                </div>
              </div>
            </div>

            <div class="flexColCenter single-blog">
              <img :src="$Image('subscribe-to-youtube.png')" alt="subscribe to youtube channel">
              <span>{{$t('Subscribe to YouTube')}}</span>
              <div class="blog-content">
                <h3>
                  <a href="https://www.youtube.com/channel/UCxBfdONiMTIE_oB2DoKkthg" target="_blank">{{$t('Subscribe to YouTube Channel')}}</a>
                </h3>
                <p class="text-white">{{$t("Subscribe to our YouTube channel and get 60 free credits. Please send us your YouTube channel ID and registration email (you signed up for our service) to support@avclabs.com and we'll add the credits to your account.")}}</p>
                <div @click="onSubscribe" class="read-more-for-credits">
                    {{$t('Subscribe to Get 60 Credits')}}
                </div>
              </div>
            </div>

          </div>

        </el-tab-pane>
        <el-tab-pane :label="$t('Review Us')" name="second">
          <div class="review_us">
            <div class="flexRowCenter review_d">

              <img :src="$Image('review-us.png')" alt="">
              <div class="flexColCenter">

                <div class="expert-team-content with-left">
                  <h3>{{$t('Review Us to Get 60 Free Credits')}}</h3>
                  <p>{{$t('1. Write a detailed review of AVCLabs with more than 200 words on Trustpilot and Alternativeto.')}}</p>
                  <p>{{$t("2. Once your review was posted, please send us a screenshot to support@avclabs.com, and we'll add the credits to your account.")}}</p>
                  <div class="flexRowStart review_us_btn">
                    <a href="https://trustpilot.com/review/avclabs.com" target="_blank" class="default-btn normal_btn">{{$t('Review on Trustpilot')}} </a>
                  
                    <a href="https://alternativeto.net/software/avclabs-photo-enhancer-ai/" target="_blank" class="default-btn active-1">
                    {{$t('Review on Alternativeto')}}
                    
                    </a>
                  </div>
   
                </div>
                

              </div>

            </div>

          </div>
        </el-tab-pane>
      </el-tabs>

    </div>
  
    <FooterView></FooterView>
  </div>
</div>

</template>
<script> 
import { getCookie } from '@/assets/js/utils/cookie'
import FooterView from '../common/Footer.vue' 
export default {
  name: 'CreditsView',
  data() {
    return {
      activeName: 'first'
    }
  },
  components: {
    FooterView
  },
  methods: {
    onSubscribe () {
      const lang = getCookie('lang')
      switch (lang) {
        case 'ja':
          window.open('https://www.youtube.com/channel/UCbWJbV_pG5n1Hv6EQqnUaCw')
          break
        default:
          window.open('https://www.youtube.com/channel/UCxBfdONiMTIE_oB2DoKkthg')
          break
      }
    },
    onFollow () {
      const lang = getCookie('lang')
      switch (lang) {
        case 'ja':
          window.open('https://twitter.com/AVCLabsJapan')
          break
        default:
          window.open('https://twitter.com/AVCLabsofficial')
          break
      }
    }
   
  },

  mounted () {
    
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/scss/module/credits.scss';
</style>
